body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
li,
input {
  font-family: 'Poppins', sans-serif;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.margin-5 {
  margin: 5px !important;
}
.navbar {
  position: fixed;
  min-height: 85px;

  border-bottom-color: #e9e9e9;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

  background: #fff;
  padding: 8px 0;
  transition: min-height 0.3s ease;
  width: 100%;
  z-index: 1001;
}
.navbar-brand {
  float: left;
  height: 65px;
  padding: 6px 0 4px 0;
  font-size: 18px;
  line-height: 20px;
}

.navbar .container {
  position: relative;
  display: table;
  display: block;
}

@media (min-width: 1250px) {
  .navbar .container {
    width: 1300px;
  }
}

.navbar .container .navbar-header {
  margin: 0;
}

.navbar .container .navbar-nav {
  margin: 12px 0 0;
  min-height: 45px;
}

main {
  padding-top: 85px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}

ul.nav > li > a {
  font-size: 13px;
  padding: 10px 20px;

  font-weight: 600;
  text-transform: uppercase;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #555;
  background-color: transparent;
}

.home-intro {
  background-color: #171717;
  overflow: hidden;
  padding: 20px 0 10px 0;
  position: relative;
  text-align: left;
}

/* Background Image */
.background-image {
  background: url(../images/seawall_v2.jpg) no-repeat center center;
  background-size: cover;
  background-position-y: 75%;
  width: 100%;
  height: 500px;
}

.navbar-default .navbar-nav > li > a {
  color: black !important;
}

#public-header-dropdown {
  color: black !important;
}

.parallax {
  min-height: 400px;
}

@media (min-width: 1110px) {
  .parallax {
    min-height: 500px;
  }
}

.parallax-image {
  transform: translateY(-40%) scale(0.85);
}

.parallax-title {
  font-size: 60px;
  text-transform: uppercase;
  margin-top: 180px;
  text-align: center;
  color: white;
  text-shadow: black 2px 2px 5px;
}

@media (max-width: 550px) {
  .parallax-title {
    font-size: 40px;
  }
}

/* Intro bar */
.home-intro {
  float: left;
  width: 100%;
}

.home-intro p {
  color: #fff;
  display: inline-block;
  font-size: 1.4em;
  font-weight: 300;
  max-width: 800px;
  padding-top: 5px;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mb-xs {
  margin-bottom: 5px !important;
}

.mt-xs {
  margin-top: 5px !important;
}

.text-color-primary {
  color: #6a80a9 !important;
}

.custom-home-intro p span:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: -40px;
  width: 30px;
  border-bottom: 1px solid #fff;
  transform: translateY(-50%);
}

.custom-home-intro p span:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: -44px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #fff;
  transform: translateY(-50%);
}

.home-intro p span {
  color: #999;
  display: block;
  font-size: 0.8em;
  padding-top: 5px;
}

.custom-home-intro p span {
  position: relative;
  display: inline-block;
  padding-top: 0;
}

.btn-primary {
  color: #ffffff;
  background-color: #6a80a9;
  border-color: #6a80a9 #6a80a9 #52678e;
}
.btn-primary-1 {
  color: #ffffff;
  background-color: #385a9b;
  border-color: #385a9b #385a9b #385a9b;
}
.custom-btn-style-1 {
  padding: 13px 25px !important;
  font-size: 12px;
  border-radius: 2px !important;
}

.pull-right {
  float: right !important;
}

.text-color-primary {
  color: #6a80a9 !important;
}

.mt-sm {
  margin-top: 10px !important;
}

.section {
  padding: 25px 0;
}

.section.top {
  padding-top: 50px;
}

section.bottom {
  padding-bottom: 50px;
}

.section.background {
  background-color: #fbfbfb;
}

.section .list li {
  margin-bottom: 5px;
}

.heading h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.8em;
  line-height: 27px;
  margin: 0;
  color: #2e353e;
}

.heading p.no-h {
  margin-top: 20px;
}

.heading h2.no-p {
  margin-bottom: 20px;
}

.heading p {
  margin-bottom: 30px;
  font-size: 1.6rem;
  color: #2e353e;
  line-height: 24px;
  margin: 0 0 20px;
}

/* section container */
.section-container {
  padding: 70px 0px;
  background-color: #f4f4f4;
}
/* half sections */
.half-section-container {
  clear: both;
}
.parallax-half-section {
  min-height: 315px;
}

.half-section-container .half-section {
  background-color: #f4f4f4;
  min-height: 315px;
  padding: 0;
}

.half-section-container .half-section section {
  padding: 50px;
}
.btn-outline.btn-primary {
  color: #6a80a9;
  background-color: transparent;
  background-image: none;
  border-color: #6a80a9;
  padding: 13px 30px;
  font-size: 12px;
  border-width: 2px;
  border-radius: 0;
}
.btn-outline.btn-primary-1 {
  color: #385a9b;
  background-color: transparent;
  background-image: none;
  border-color: #385a9b;
  padding: 13px 30px;
  font-size: 12px;
  border-width: 2px;
  border-radius: 0;
}
@media (max-width: 991px) {
  .custom-sm-flex-order {
    display: flex;
    flex-flow: row wrap;
  }

  .custom-sm-flex-order .col-md-6:nth-child(1) {
    order: 2;
  }
  .custom-sm-flex-order .col-md-6:nth-child(2) {
    order: 1;
  }
  .custom-sm-flex-order .col-md-6 {
    width: 100%;
  }
}

/* Footer */
.custom-footer {
  font-size: 0.9em;
  position: relative;
  clear: both;
}

.footer-copyright {
  padding: 30px 0 10px;
}

.custom-footer .footer-copyright .container .row > div {
  border-top: 1px solid #e4e4e4;
}

footer .container .row > div {
  margin-bottom: 25px;
}

footer .logomark {
  margin-top: 20px;
}

.custom-text-color-1 {
  color: #000 !important;
  font-size: 15px !important;
  text-align: justify;
  text-justify: inter-word;
}

@media only screen and (max-width: 600px) {
  .custom-text-color-1 {
    color: #000 !important;
    font-size: 15px !important;
    text-align: left;
  }
}

.pt-md {
  padding-top: 15px !important;
}

.align-center,
.center {
  text-align: center !important;
}

h5 {
  font-size: 1em;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0 0 14px 0;
  text-transform: uppercase;
}

html .heading-dark,
html .lnk-dark,
html .text-color-dark {
  color: #2e353e !important;
}

.mb-xs {
  margin-bottom: 5px !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.custom-footer ul {
  padding: 0;
}

.custom-footer ul li {
  list-style: none;
}

li {
  line-height: 24px;
}

.wrap {
  padding: 100px 0px 20px 0px;
}

/* Listings */
section {
  display: block;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

.page-header {
  background-color: #171717;
  border-bottom: 5px solid #ccc;
  border-top: 5px solid #384045;
  margin: 0 0 35px 0;
  min-height: 50px;
  padding: 20px 0;
  position: relative;
  text-align: left;
}

.page-header-more-padding {
  padding: 35px 0;
}

.page-header-light {
  background-color: #f7f7f7;
  border-bottom: 5px solid #f4f4f4;
  border-top: none;
}

.page-header-light {
  border-bottom: 5px solid #f7f7f7;
}

.page-header h1 {
  border-bottom: 5px solid #ccc;
  color: #fff;
  display: inline-block;
  font-size: 32px;
  font-weight: 200;
  line-height: 32px;
  margin: 0 0 -25px;
  padding: 0 0 25px;
  position: relative;
}

.page-header-light h1 {
  font-weight: 400;
  font-size: 2em;
}

.page-header-light h1 {
  border-bottom: none;
  color: #1d2127;
  font-size: 2.3em;
}

.page-header h1 span {
  color: #999da3;
  display: block;
  font-size: 0.6em;
  font-weight: 300;
  line-height: 1.3em;
  margin: 7px 0 0;
}

.mt-lg {
  margin-top: 20px !important;
}

/* listing */

.properties-listing .row > div {
  padding: 15px;
}

.listing-item {
  transition: all 0.3s ease;
}
.custom-modal {
  width: 65vw;
  height: 65vh;
}
.thumb-info {
  display: block;
  position: relative;
  text-decoration: none;
  max-width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.thumb-info .thumb-info-wrapper {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 4px;
  margin: 4px;
  overflow: hidden;
  display: block;
  position: relative;
  margin: 0 !important;
}

.thumb-info img {
  transition: all 0.3s ease;
  border-radius: 3px;
  position: relative;
  width: 100%;
}

.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.properties-listing .listing-item .thumb-info .thumb-info-listing-type {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #219cd2 !important;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}

.thumb-info.thumb-info-lighten .thumb-info-wrapper:after {
  background: rgba(23, 23, 23, 0.25);
}

.thumb-info .thumb-info-wrapper:after {
  content: '';
  background: rgba(23, 23, 23, 0.8);
  transition: all 0.3s;
  border-radius: 4px;
  bottom: -4px;
  color: #fff;
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
  display: block;
  opacity: 0;
  z-index: 1;
}

.properties-listing .listing-item .thumb-info .thumb-info-price {
  display: block;
  width: 100%;
  line-height: 1;
  background-color: #333b48;
  color: #ffffff;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.9rem;
}

.properties-listing .listing-item .thumb-info .thumb-info-price span {
  color: #219cd2;
  position: relative;
}

.properties-listing .listing-item .thumb-info .thumb-info-price span:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 40px;
  border-bottom: 1px solid #219cd2;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.properties-listing .listing-item .thumb-info .custom-thumb-info-title {
  display: block;
  padding: 20px;
  border-width: 5px;
}

.properties-listing
  .listing-item
  .thumb-info
  .custom-thumb-info-title
  ul
  li:nth-child(1) {
  padding-left: 0;
}

.properties-listing
  .listing-item
  .thumb-info
  .custom-thumb-info-title
  ul
  li:nth-child(2) {
  border-left: 1px solid #b8b8b8;
  border-right: 1px solid #b8b8b8;
}

.listing-item .thumb-info .custom-thumb-info-title ul li {
  display: inline-block;
  line-height: 1;
  padding: 1px 5px;
  list-style: none;
}

.thumb-info .thumb-info-inner {
  transition: all 0.3s;
  display: block;
  font-size: 1.6rem;
}

.accommodations {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.3rem;
}

.accommodations .accomodation-value {
  color: #2bca6e;
}

.list.list-icons {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}

.list.list-icons li {
  position: relative;
  padding-left: 25px;
}

.list li {
  margin-bottom: 13px;
}

html .list-secondary.list-icons li > .fa:first-child,
html .list-secondary.list-icons li a:first-child > .fa:first-child,
html .list-secondary.list-icons li > .icons:first-child,
html .list-secondary.list-icons li a:first-child > .icons:first-child {
  color: #219cd2;
  border-color: #219cd2;
}

.list.list-icons li > .fa:first-child,
.list.list-icons li > .icons:first-child,
.list.list-icons li a:first-child > .fa:first-child,
.list.list-icons li a:first-child > .icons:first-child {
  position: absolute;
  left: 0;
  top: 5px;
}

.custom-list-item-disabled {
  color: #c9c9c9 !important;
  text-decoration: line-through;
}

.google-map {
  background: #e5e3df;
  height: 400px;
  margin: -35px 0 30px 0;
  width: 100%;
}

.mb-xlg {
  margin-bottom: 30px !important;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.react-parallax-content h1 {
  text-align: center;
  padding: 50px;
  text-transform: uppercase;
  color: white;
  text-shadow: black 2px 2px 5px;
}

/* Contact */

.contact-section {
  background: #f4f4f4;
  padding: 60px 0;
}

.contact-section .icon {
  font-size: 3.5rem;
  color: #5b739e;
}
.contact-section ul {
  padding: 0;
}

.contact-section ul li {
  list-style: none;
}

.contact-section ul li a {
  color: #333;
  text-decoration: none;
}

.contact-section ul li .title {
  display: inline-block;
  width: 80px;
  font-weight: 600;
}

.contact-section ul li .fa {
  width: 20px;
}

.thumbnail {
  width: 95%;
  height: 300px;
  /*opacity: 0.4;*/
  background: white;
  overflow: hidden;
  border: white;
  padding: 0;
}

.thumbnail img {
  width: 100%;
  height: 65%;
  display: block;
  position: relative;
  /*opacity: 0.4;*/
  border: 1px solid white;
}

.thumbnail:hover {
  opacity: 1;
  background: white;
}

.thumbnail img:hover {
  opacity: 1;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  /*-webkit-transition: 0.6s ease;*/
  /*transition: 0.6s ease;*/

  /*transform: translate(200px, 150px) rotate(20deg);*/
}

.div1 {
  width: 100%;
  height: 100%;
  left: 0%;
  border: 1px solid #409bc8;
  background: #409bc8;
  position: relative;
  padding: 80px;
}

.div2 {
  width: 100%;
  position: relative;
  background: #3772ea;
  font-family: Arial;
  font-size: 25px;
  height: 100%;
  /*font-weight: bold;*/
  color: white;
  padding: 100px;
}

.div3 {
  width: 100%;
  height: 100%;
  background: #0149d8;
  font-family: Arial;
  font-size: 25px;
  padding: 100px;
  color: white;
  /*font-weight: bold;*/
}

.div4 {
  width: 100%;
  height: 100%;
  background: #2ee8f5;
  font-family: Arial;
  font-size: 25px;
  padding: 100px;
  color: white;
}

.divCareers {
  width: 100%;
  height: 100%;
  font-family: Arial;
  font-size: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 200px;
  padding-right: 200px;
}

.careerSign {
  width: 50%;
  height: 100%;
  font-family: Arial;
  font-size: 15px;
  padding: 20px;
  background: #39aeee;
}

.padRow {
  padding: 20px;
}

.numberText {
  font-family: Arial;
  font-size: 25px;
  /*font-weight: bold;*/
  color: white;
}

.horVerCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horVerLeft {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
}

.horVerRight {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
}

.divModal {
  overflow: hidden;
  float: left;
}

.divModal2 {
  overflow: hidden;
  padding-left: 20px;
}

.imageHoverEffect:hover {
  border: 2px solid #ff9933;
  cursor: pointer;
}

.imageHoverEffect {
  width: 100%;
  height: 100%;
  border: 2px solid white;
}

.recaptcha {
  transform: translate(-50%, 0%);
  display: inline-block;
  margin-left: 50%;
  padding-top: 6px;
  padding-bottom: 18px;
}

.timePicker {
  display: block !important;
  height: 36px !important;
}

.react-time-picker__wrapper {
  display: flex;
  border: thin solid gray;
  height: 34px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

/* modal */
.iframe {
  border: 0;
}

.custom-modal {
  height: 815px;
  width: 730px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  border: #858484 5px solid;
  background-color: white;
}

.modal-button {
  margin-top: 10px;
  margin-right: 5px;
}

@media (max-height: 800px) {
  .custom-modal {
    height: 530px;
    padding-bottom: 10px;
  }
}

@media (min-height: 800px) and (max-height: 900px) {
  .custom-modal {
    height: 680px;
  }
}

@media (max-width: 830px) {
  .custom-modal {
    margin-top: 82px;
    width: auto;
    border: 0;
  }

  .modal-button {
    margin-top: 0;
  }
}

@media (min-width: 750px) and (max-width: 1200px) {
  .custom-modal {
    margin-top: 200px;
  }
}

/* Footer */
.margin-zero-auto {
  margin: 0 auto;
}

.custom-footer {
  font-size: 0.9em;
  position: relative;
  clear: both;
  background-color: #6a80a9;
  padding-top: 20px;
}

.footer-copyright {
  padding: 30px 0 10px;
  background-color: #6a80a9;
}

.custom-footer .footer-copyright .container .row > div {
  border-top: 1px solid #e4e4e4;
}

footer .container .row > div {
  margin-bottom: 25px;
}

footer .logomark {
  margin-top: 20px;
}

.custom-text-color-1 {
  color: #000 !important;
  font-size: 15px !important;
  text-align: justify;
  text-justify: inter-word;
}

.custom-text-color-children > p {
  color: #000 !important;
  font-size: 15px !important;
  text-align: justify;
  text-justify: inter-word;
}

@media only screen and (max-width: 600px) {
  .custom-text-color-1 {
    color: #000 !important;
    font-size: 15px !important;
    text-align: left;
  }
}

.pt-md {
  padding-top: 15px !important;
}

.align-center,
.center {
  text-align: center !important;
}

h5 {
  font-size: 1em;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0 0 14px 0;
  text-transform: uppercase;
}

html .heading-dark,
html .lnk-dark,
html .text-color-dark {
  color: #2e353e !important;
}

.mb-xs {
  margin-bottom: 5px !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.custom-footer ul {
  padding: 0;
}

.custom-footer ul li {
  list-style: none;
}

li {
  line-height: 24px;
}

.wrap {
  padding: 100px 0px 20px 0px;
}

.footer-button {
  padding: 15px 20px !important;
  margin-right: 30px;
}

@media (min-width: 350px) {
  .footer-button {
    margin: 5px;
    font-size: 12px !important;
  }
}

.scale-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.new-Images {
  height: 100px;
  width: 1170px;
}

.button-align-right {
  float: right;
}
