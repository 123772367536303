@charset "UTF-8";
/*
Name: 			theme-elements.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	5.7.2
*/
/* Headings */
h1, h2, h3, h4, h5, h6 {
	color: #1d2127;
	font-weight: 200;
	letter-spacing: -1px;
	margin: 0;
}

h1 {
	font-size: 2.6em;
	line-height: 44px;
	margin: 0 0 32px 0;
}

h2 {
	font-size: 2.2em;
	font-weight: 300;
	line-height: 42px;
	margin: 0 0 32px 0;
}

h3 {
	font-size: 1.8em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 24px;
	margin-bottom: 33px;
	text-transform: uppercase;
}

h4 {
	font-size: 1.4em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 27px;
	margin: 0 0 14px 0;
}

h5 {
	font-size: 1em;
	font-weight: 600;
	letter-spacing: normal;
	line-height: 18px;
	margin: 0 0 14px 0;
	text-transform: uppercase;
}

h6 {
	color: #333;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 18px;
	margin: 0 0 14px 0;
}

h1.big {
	font-size: 4.2em;
	line-height: 54px;
}

h1.small {
	font-size: 2.2em;
	line-height: 42px;
	font-weight: 600;
}

h1.short {
	margin-bottom: 15px;
}

h2.short {
	margin-bottom: 15px;
}

h3.short,
h4.short,
h5.short,
h6.short {
	margin-bottom: 9px;
}

h1.shorter,
h2.shorter,
h3.shorter,
h4.shorter,
h5.shorter,
h6.shorter {
	margin-bottom: 0;
}

h1.tall,
h2.tall,
h3.tall,
h4.tall,
h5.tall,
h6.tall {
	margin-bottom: 33px;
}

h1.taller,
h2.taller,
h3.taller,
h4.taller,
h5.taller,
h6.taller {
	margin-bottom: 44px;
}

h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced {
	margin-top: 22px;
}

h1.more-spaced,
h2.more-spaced,
h3.more-spaced,
h4.more-spaced,
h5.more-spaced,
h6.more-spaced {
	margin-top: 44px;
}

h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark {
	color: #1d2127;
}

h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
	color: #FFF;
}

h1.text-shadow,
h2.text-shadow,
h3.text-shadow,
h4.text-shadow,
h5.text-shadow,
h6.text-shadow {
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

/* Heading */
.heading.heading-border {
	margin-bottom: 25px;
}

.heading.heading-border h1, .heading.heading-border h2, .heading.heading-border h3, .heading.heading-border h4, .heading.heading-border h5, .heading.heading-border h6 {
	margin: 0;
	padding: 0;
}

.heading.heading-bottom-border h1 {
	border-bottom: 5px solid #DBDBDB;
	padding-bottom: 10px;
}

.heading.heading-bottom-border h2, .heading.heading-bottom-border h3 {
	border-bottom: 2px solid #DBDBDB;
	padding-bottom: 10px;
}

.heading.heading-bottom-border h4, .heading.heading-bottom-border h5, .heading.heading-bottom-border h6 {
	border-bottom: 1px solid #DBDBDB;
	padding-bottom: 5px;
}

.heading.heading-bottom-double-border h1, .heading.heading-bottom-double-border h2, .heading.heading-bottom-double-border h3 {
	border-bottom: 3px double #DBDBDB;
	padding-bottom: 10px;
}

.heading.heading-bottom-double-border h4, .heading.heading-bottom-double-border h5, .heading.heading-bottom-double-border h6 {
	border-bottom: 3px double #DBDBDB;
	padding-bottom: 5px;
}

.heading.heading-middle-border {
	position: relative;
}

.heading.heading-middle-border:before {
	border-top: 1px solid #DBDBDB;
	content: "";
	height: 0;
	left: auto;
	position: absolute;
	right: 0;
	top: 50%;
	width: 100%;
}

.heading.heading-middle-border h1, .heading.heading-middle-border h2, .heading.heading-middle-border h3, .heading.heading-middle-border h4, .heading.heading-middle-border h5, .heading.heading-middle-border h6 {
	position: relative;
	background: #FFF;
	display: inline-block;
}

.heading.heading-middle-border h1 {
	padding-right: 15px;
}

.heading.heading-middle-border h2, .heading.heading-middle-border h3 {
	padding-right: 10px;
}

.heading.heading-middle-border h4, .heading.heading-middle-border h5, .heading.heading-middle-border h6 {
	padding-right: 5px;
}

.heading.heading-middle-border-reverse {
	text-align: right;
}

.heading.heading-middle-border-reverse h1, .heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse h3, .heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse h6 {
	padding-right: 0;
}

.heading.heading-middle-border-reverse h1 {
	padding-left: 15px;
}

.heading.heading-middle-border-reverse h2, .heading.heading-middle-border-reverse h3 {
	padding-left: 10px;
}

.heading.heading-middle-border-reverse h4, .heading.heading-middle-border-reverse h5, .heading.heading-middle-border-reverse h6 {
	padding-left: 5px;
}

.heading.heading-middle-border-center {
	text-align: center;
}

.heading.heading-middle-border-center h1 {
	padding-left: 15px;
	padding-right: 15px;
}

.heading.heading-middle-border-center h2, .heading.heading-middle-border-center h3 {
	padding-left: 10px;
	padding-right: 10px;
}

.heading.heading-middle-border-center h4, .heading.heading-middle-border-center h5, .heading.heading-middle-border-center h6 {
	padding-left: 5px;
	padding-right: 5px;
}

.heading.heading-border-xs:before {
	border-top-width: 1px;
}

.heading.heading-border-sm:before {
	border-top-width: 2px;
}

.heading.heading-border-lg:before {
	border-top-width: 3px;
}

.heading.heading-border-xl:before {
	border-top-width: 5px;
}

/* Modal Titles */
.modal-header h3 {
	color: #333;
	text-transform: none;
}

/* Responsive */
@media (max-width: 479px) {
	h2 {
		line-height: 40px;
	}
}

@media (max-width: 767px) {
	h1.big {
		font-size: 3.2em;
		line-height: 42px;
	}
}
/* Blockquote */
blockquote {
	font-size: 1em;
}

blockquote.with-borders {
	border-radius: 6px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	padding: 15px 20px;
}

/* Paragraphs */
p {
	color: #777;
	line-height: 24px;
	margin: 0 0 20px;
}

p.featured {
	font-size: 1.6em;
	line-height: 1.5em;
}

p.short {
	margin-bottom: 0;
}

p.tall {
	margin-bottom: 20px;
}

p.taller {
	margin-bottom: 40px;
}

p .alternative-font {
	display: inline-block;
	margin-top: -15px;
	position: relative;
	top: 3px;
	margin-bottom: -6px;
}

/* Links */
a, a:hover, a:focus {
	color: #CCC;
}

/* Drop Caps */
p.drop-caps:first-letter {
	float: left;
	font-size: 75px;
	line-height: 60px;
	padding: 4px;
	margin-right: 5px;
	margin-top: 5px;
	font-family: Georgia;
}

p.drop-caps.drop-caps-style-2:first-letter {
	background-color: #CCC;
	color: #FFF;
	padding: 6px;
	margin-right: 5px;
	border-radius: 4px;
}

/* Default Font Style */
.default-font {
	font-family: "Open Sans", Arial, sans-serif;
}

/* Alternative Font Style */
.alternative-font {
	color: #CCC;
	font-family: "Shadows Into Light", cursive;
	font-size: 1.6em;
}

/* Animations */
.appear-animation {
	opacity: 0;
}

.appear-animation-visible {
	opacity: 1;
}

/* Nav */
.nav > li > a:hover, .nav > li > a:focus {
	background: transparent;
}

/* Nav List */
ul.nav-list li {
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
}

ul.nav-list li a {
	-webkit-transition: background 0.1s;
	-moz-transition: background 0.1s;
	transition: background 0.1s;
	border-bottom: 1px solid #EDEDDE;
	padding: 8px 0 8px 18px;
	display: block;
	color: #666;
	font-size: 0.9em;
	text-decoration: none;
	line-height: 20px;
}

ul.nav-list li a:before {
	content: "";
	display: inline-block;
	width: 0;
	height: 0;
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	border-left: 4px solid #333;
	margin: 7px 0 0 -9px;
	position: absolute;
}

ul.nav-list li a:hover {
	background-color: #eee;
	text-decoration: none;
}

ul.nav-list li.active > a {
	font-weight: bold;
}

ul.nav-list li ul {
	list-style: none;
	margin: 0 0 0 25px;
	padding: 0;
}

ul.nav-list.hide-not-active li ul {
	display: none;
}

ul.nav-list.hide-not-active li.active ul {
	display: block;
}

ul.nav-list.show-bg-active .active > a {
	background-color: #f5f5f5;
}

ul.nav-list.show-bg-active .active > a:hover {
	background-color: #eee;
}

ul.narrow li a {
	padding: 4px 0;
}

ul.nav-pills > li.active > a {
	background-color: #CCC;
}

ul.nav-pills > li.active > a:hover, ul.nav-pills > li.active > a:focus {
	background-color: #CCC;
}

/* Nav Pills */
.nav-pills-center {
	text-align: center;
}

.nav-pills-center > li {
	display: inline-block;
	float: none;
}

.nav-pills-sm > li > a {
	padding: 7px 12px;
}

.nav-pills-xs > li > a {
	padding: 5px 10px;
	font-size: 0.9em;
}

/* Nav Secondary */
.nav-secondary {
	background: #fafafa;
	margin: 0;
	padding: 20px 0;
	text-align: center;
	z-index: 99;
	width: 100% !important;
}

.nav-secondary .nav-pills > li {
	float: none;
	display: inline-block;
}

.nav-secondary .nav-pills > li a {
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	padding: 10px 13px;
	text-transform: uppercase;
}

.nav-secondary.sticky-active {
	border-bottom-color: #e9e9e9;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

/* Boxed */
html.boxed .nav-secondary.sticky-active {
	max-width: 1200px;
	left: auto !important;
}

/* Buttons */
.btn {
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.btn-default {
	color: #666;
}

.btn-icon i {
	margin-right: 10px;
}

.btn-icon-right i {
	margin-right: 0;
	margin-left: 10px;
}

.btn-primary {
	border-color: #CCC;
	background-color: #CCC;
	border-color: #CCC #CCC #b3b3b3;
	color: #FFF;
}

.btn-primary:hover {
	border-color: #d9d9d9 #d9d9d9 #bfbfbf;
	background-color: #d9d9d9;
	color: #FFF;
}

.btn-primary:active, .btn-primary:focus {
	border-color: #bfbfbf;
	background-color: #bfbfbf;
	color: #FFF;
}

.btn-primary.dropdown-toggle {
	border-left-color: #bfbfbf;
}

.btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:active, .btn-primary[disabled]:focus {
	border-color: white;
	background-color: white;
	color: #CCC;
}

.btn-success {
	border-color: #47a447;
	background-color: #47a447;
	border-color: #47a447 #47a447 #388038;
	color: #FFF;
}

.btn-success:hover {
	border-color: #51b451 #51b451 #3f923f;
	background-color: #51b451;
	color: #FFF;
}

.btn-success:active, .btn-success:focus {
	border-color: #3f923f;
	background-color: #3f923f;
	color: #FFF;
}

.btn-success.dropdown-toggle {
	border-left-color: #3f923f;
}

.btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:active, .btn-success[disabled]:focus {
	border-color: #86cb86;
	background-color: #86cb86;
	color: #CCC;
}

.btn-warning {
	border-color: #ed9c28;
	background-color: #ed9c28;
	border-color: #ed9c28 #ed9c28 #d18211;
	color: #FFF;
}

.btn-warning:hover {
	border-color: #efa740 #efa740 #e89113;
	background-color: #efa740;
	color: #FFF;
}

.btn-warning:active, .btn-warning:focus {
	border-color: #e89113;
	background-color: #e89113;
	color: #FFF;
}

.btn-warning.dropdown-toggle {
	border-left-color: #e89113;
}

.btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:active, .btn-warning[disabled]:focus {
	border-color: #f5c786;
	background-color: #f5c786;
	color: #CCC;
}

.btn-danger {
	border-color: #d2322d;
	background-color: #d2322d;
	border-color: #d2322d #d2322d #a82824;
	color: #FFF;
}

.btn-danger:hover {
	border-color: #d74742 #d74742 #bd2d29;
	background-color: #d74742;
	color: #FFF;
}

.btn-danger:active, .btn-danger:focus {
	border-color: #bd2d29;
	background-color: #bd2d29;
	color: #FFF;
}

.btn-danger.dropdown-toggle {
	border-left-color: #bd2d29;
}

.btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:active, .btn-danger[disabled]:focus {
	border-color: #e48481;
	background-color: #e48481;
	color: #CCC;
}

.btn-info {
	border-color: #5bc0de;
	background-color: #5bc0de;
	border-color: #5bc0de #5bc0de #31b0d5;
	color: #FFF;
}

.btn-info:hover {
	border-color: #70c8e2 #70c8e2 #46b8da;
	background-color: #70c8e2;
	color: #FFF;
}

.btn-info:active, .btn-info:focus {
	border-color: #46b8da;
	background-color: #46b8da;
	color: #FFF;
}

.btn-info.dropdown-toggle {
	border-left-color: #46b8da;
}

.btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:active, .btn-info[disabled]:focus {
	border-color: #b0e1ef;
	background-color: #b0e1ef;
	color: #CCC;
}

.btn-dark {
	border-color: #1d2127;
	background-color: #1d2127;
	border-color: #1d2127 #1d2127 #07080a;
	color: #FFF;
}

.btn-dark:hover {
	border-color: #282d36 #282d36 #121518;
	background-color: #282d36;
	color: #FFF;
}

.btn-dark:active, .btn-dark:focus {
	border-color: #121518;
	background-color: #121518;
	color: #FFF;
}

.btn-dark.dropdown-toggle {
	border-left-color: #121518;
}

.btn-dark[disabled], .btn-dark[disabled]:hover, .btn-dark[disabled]:active, .btn-dark[disabled]:focus {
	border-color: #495362;
	background-color: #495362;
	color: #CCC;
}

/* Border Buttons */
.btn-borders {
	border-width: 3px;
}

.btn-borders.btn-primary {
	background: transparent;
	border-color: #CCC;
	color: #CCC;
	text-shadow: none;
}

.btn-borders.btn-primary:hover, .btn-borders.btn-primary:focus {
	background-color: #d9d9d9;
	border-color: #CCC !important;
	color: #FFF;
}

.btn-borders.btn-success {
	background: transparent;
	border-color: #47a447;
	color: #47a447;
	text-shadow: none;
}

.btn-borders.btn-success:hover, .btn-borders.btn-success:focus {
	background-color: #51b451;
	border-color: #47a447 !important;
	color: #FFF;
}

.btn-borders.btn-warning {
	background: transparent;
	border-color: #ed9c28;
	color: #ed9c28;
	text-shadow: none;
}

.btn-borders.btn-warning:hover, .btn-borders.btn-warning:focus {
	background-color: #efa740;
	border-color: #ed9c28 !important;
	color: #FFF;
}

.btn-borders.btn-danger {
	background: transparent;
	border-color: #d2322d;
	color: #d2322d;
	text-shadow: none;
}

.btn-borders.btn-danger:hover, .btn-borders.btn-danger:focus {
	background-color: #d74742;
	border-color: #d2322d !important;
	color: #FFF;
}

.btn-borders.btn-info {
	background: transparent;
	border-color: #5bc0de;
	color: #5bc0de;
	text-shadow: none;
}

.btn-borders.btn-info:hover, .btn-borders.btn-info:focus {
	background-color: #70c8e2;
	border-color: #5bc0de !important;
	color: #FFF;
}

.btn-borders.btn-dark {
	background: transparent;
	border-color: #1d2127;
	color: #1d2127;
	text-shadow: none;
}

.btn-borders.btn-dark:hover, .btn-borders.btn-dark:focus {
	background-color: #282d36;
	border-color: #1d2127 !important;
	color: #FFF;
}

/* Border Buttons - Sizes */
.btn-borders {
	padding: 4px 12px;
}

.btn-borders.btn-lg, .btn-group-lg > .btn-borders.btn {
	padding: 8px 16px;
}

.btn-borders.btn-sm, .btn-group-sm > .btn-borders.btn {
	border-width: 2px;
	padding: 4px 10px;
}

.btn-borders.btn-xs, .btn-group-xs > .btn-borders.btn {
	padding: 1px 5px;
	border-width: 1px;
}

/* 3D Buttons */
.btn-3d {
	border-bottom-width: 3px;
	padding: 5px 12px;
	border-radius: 6px;
}

.btn-3d.btn-lg, .btn-group-lg > .btn-3d.btn {
	padding: 9px 16px;
}

.btn-3d.btn-sm, .btn-group-sm > .btn-3d.btn {
	border-width: 2px;
	padding: 4px 10px;
}

.btn-3d.btn-xs, .btn-group-xs > .btn-3d.btn {
	padding: 1px 5px;
	border-width: 1px;
}

/* Sizes */
.btn-xlg {
	border-radius: 6px;
	font-size: 16px;
	padding: 12px 33px;
}

/* Labels */
.label-primary {
	background-color: #CCC;
	color: #FFF;
}

.label-success {
	background-color: #47a447;
	color: #FFF;
}

.label-warning {
	background-color: #ed9c28;
	color: #FFF;
}

.label-danger {
	background-color: #d2322d;
	color: #FFF;
}

.label-info {
	background-color: #5bc0de;
	color: #FFF;
}

.label-dark {
	background-color: #1d2127;
	color: #FFF;
}

.label.label-sm {
	font-size: 0.7em;
}

.label.label-lg {
	font-size: 1em;
}

/* Forms */
form label {
	font-weight: normal;
}

textarea {
	resize: vertical;
}

select {
	border: 1px solid #E5E7E9;
	border-radius: 6px;
	outline: none;
}

.label {
	font-weight: normal;
}

.form-group:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.form-control:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	border-color: #CCC;
}

.form-control.error {
	border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control.error:focus {
	border-color: #843534;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

/* Forms Validations */
label.valid {
	display: inline-block;
	text-indent: -9999px;
}

label.error {
	color: #c10000;
	font-size: 0.9em;
	line-height: 18px;
	padding: 5px 0 0;
}

label.error#captcha-error {
	margin-top: 15px;
}

/* Captcha */
.captcha {
	clear: both;
	overflow: hidden;
	background: #F2F2F2;
	text-align: center;
	margin-bottom: 20px;
	max-width: 160px;
	height: 78px;
	position: relative;
}

.captcha input {
	border: 0;
}

.captcha-input {
	position: relative;
	top: 15px;
	font-size: 14px;
}

.captcha-image {
	float: left;
	position: relative;
	top: -5px;
	left: -13px;
}

.captcha-refresh {
	position: absolute;
	z-index: 1;
	bottom: 2px;
	right: 2px;
	border-radius: 3px;
	padding: 2px 5px;
}

.captcha-refresh a, .captcha-refresh a:hover, .captcha-refresh a:focus, .captcha-refresh a:active {
	color: #333;
}

/* Tables */
.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {
	background-color: #CCC;
	color: #FFF;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
	background-color: #47a447;
	color: #FFF;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
	background-color: #ed9c28;
	color: #FFF;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
	background-color: #d2322d;
	color: #FFF;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
	background-color: #5bc0de;
	color: #FFF;
}

.table > thead > tr > td.dark,
.table > tbody > tr > td.dark,
.table > tfoot > tr > td.dark,
.table > thead > tr > th.dark,
.table > tbody > tr > th.dark,
.table > tfoot > tr > th.dark,
.table > thead > tr.dark > td,
.table > tbody > tr.dark > td,
.table > tfoot > tr.dark > td,
.table > thead > tr.dark > th,
.table > tbody > tr.dark > th,
.table > tfoot > tr.dark > th {
	background-color: #1d2127;
	color: #FFF;
}

/* Icons */
.featured-icon {
	background: #CCC;
	border-radius: 35px;
	color: #FFF;
	display: inline-block;
	height: 35px;
	line-height: 35px;
	margin-right: 10px;
	position: relative;
	text-align: center;
	top: 5px;
	width: 35px;
}

.featured-icon i {
	color: #FFF;
	font-size: 0.9em;
	font-weight: normal;
}

/* Font Awesome List */
.sample-icon-list {
	margin-top: 22px;
}

.sample-icon-list .sample-icon a {
	display: block;
	color: #222222;
	line-height: 32px;
	height: 32px;
	padding-left: 10px;
	border-radius: 4px;
	font-size: 11px;
}

.sample-icon-list .sample-icon a .fa, .sample-icon-list .sample-icon a .icons {
	width: 32px;
	font-size: 14px;
	display: inline-block;
	text-align: right;
	margin-right: 10px;
}

.sample-icon-list .sample-icon a:hover {
	color: #ffffff;
	text-decoration: none;
}

.sample-icon-list .sample-icon a:hover .fa, .sample-icon-list .sample-icon a:hover .icons {
	font-size: 28px;
	vertical-align: -6px;
}

/* Thumbnails */
.img-rounded {
	border-radius: 4px;
}

.img-thumbnail {
	border-radius: 4px;
	position: relative;
}

.img-thumbnail img {
	border-radius: 4px;
}

.img-thumbnail .zoom {
	background: #CCC;
	border-radius: 100%;
	bottom: 8px;
	color: #FFF;
	display: block;
	height: 30px;
	padding: 6px;
	position: absolute;
	right: 8px;
	text-align: center;
	width: 30px;
}

.img-thumbnail .zoom i {
	font-size: 14px;
	left: -1px;
	position: relative;
	top: -3px;
}

.img-thumbnail.img-thumbnail-hover-icon {
	position: relative;
}

.img-thumbnail.img-thumbnail-hover-icon img {
	position: relative;
	z-index: 1;
}

.img-thumbnail.img-thumbnail-hover-icon:before {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	background: rgba(0, 0, 0, 0.65);
	border-radius: 4px;
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	z-index: 2;
}

.img-thumbnail.img-thumbnail-hover-icon:after {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	font-family: FontAwesome;
	color: #FFF;
	content: "\f00e";
	font-size: 30px;
	height: 30px;
	position: absolute;
	top: 30%;
	margin-top: -15px;
	display: block;
	text-align: center;
	width: 100%;
	opacity: 0;
	z-index: 3;
}

.img-thumbnail.img-thumbnail-hover-icon:hover:before {
	opacity: 1;
}

.img-thumbnail.img-thumbnail-hover-icon:hover:after {
	top: 50%;
	opacity: 1;
}

/* Image Gallery */
ul.image-gallery {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.image-gallery .image-gallery-item {
	margin-bottom: 35px;
}

/* Thumbnail Gallery */
ul.thumbnail-gallery {
	list-style: none;
	margin: 10px 0;
	padding: 0;
}

ul.thumbnail-gallery li {
	display: inline-block;
	margin: 10px 10px 0 0;
	padding: 0;
}

/* Responsive */
@media (max-width: 991px) {
	.thumbnail {
		border: 0;
		text-align: center;
	}
}
/* Masonry */
.masonry .masonry-item {
	width: 25%;
}

.masonry .masonry-item.w2 {
	width: 50%;
}

.masonry .masonry-item img {
	border-radius: 0 !important;
}

/* Masonry Loading */
.masonry-loader {
	height: auto;
	overflow: visible;
	position: relative;
}

.masonry-loader:after {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
	content: ' ';
	background: #FFF;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	opacity: 0;
}

.masonry-loader .bounce-loader {
	display: none;
}

.masonry-loader.masonry-loader-showing {
	height: 200px;
	overflow: hidden;
}

.masonry-loader.masonry-loader-showing:after {
	opacity: 1;
}

.masonry-loader.masonry-loader-showing .bounce-loader {
	display: block;
}

.masonry-loader.masonry-loader-loaded:after {
	display: none;
}

/* Mockup */
@media (min-width: 992px) {
	.mockup-landing-page {
		margin-top: -120px;
	}
}
/* List */
.list li {
	margin-bottom: 13px;
}

.list.pull-left li {
	text-align: left;
}

.list.list-icons {
	list-style: none;
	padding-left: 0;
	padding-right: 0;
}

.list.list-icons li {
	position: relative;
	padding-left: 25px;
}

.list.list-icons li > .fa:first-child,
.list.list-icons li > .icons:first-child, .list.list-icons li a:first-child > .fa:first-child,
.list.list-icons li a:first-child > .icons:first-child {
	position: absolute;
	left: 0;
	top: 5px;
}

.list.list-icons.list-icons-style-2 li {
	padding-top: 5px;
	padding-left: 36px;
}

.list.list-icons.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-style-2 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-style-2 li a:first-child > .icons:first-child {
	border-width: 1px;
	border-style: solid;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	padding: 4px;
}

.list.list-icons.list-icons-style-3 li {
	padding-top: 5px;
	padding-left: 36px;
}

.list.list-icons.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-style-3 li a:first-child > .icons:first-child {
	color: #FFF;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	padding: 5px;
}

.list.list-icons.list-icons-sm li {
	padding-left: 13px;
	margin-bottom: 5px;
}

.list.list-icons.list-icons-sm li > .fa:first-child,
.list.list-icons.list-icons-sm li > .icons:first-child, .list.list-icons.list-icons-sm li a:first-child > .fa:first-child,
.list.list-icons.list-icons-sm li a:first-child > .icons:first-child {
	font-size: 0.8em;
	top: 7px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li {
	padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-2 li a:first-child > .icons:first-child {
	width: 20px;
	height: 20px;
	padding: 3px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li {
	padding-left: 32px;
}

.list.list-icons.list-icons-sm.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-sm.list-icons-style-3 li a:first-child > .icons:first-child {
	width: 20px;
	height: 20px;
	padding: 3px;
}

.list.list-icons.list-icons-lg li {
	padding-top: 5px;
	padding-left: 32px;
}

.list.list-icons.list-icons-lg li > .fa:first-child,
.list.list-icons.list-icons-lg li > .icons:first-child, .list.list-icons.list-icons-lg li a:first-child > .fa:first-child,
.list.list-icons.list-icons-lg li a:first-child > .icons:first-child {
	font-size: 1.5em;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li {
	padding-top: 8px;
	padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-2 li a:first-child > .icons:first-child {
	width: 30px;
	height: 30px;
	padding: 4px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li {
	padding-top: 8px;
	padding-left: 42px;
}

.list.list-icons.list-icons-lg.list-icons-style-3 li > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li > .icons:first-child, .list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > .fa:first-child,
.list.list-icons.list-icons-lg.list-icons-style-3 li a:first-child > .icons:first-child {
	width: 30px;
	height: 30px;
	padding: 4px;
}

.list.list-icons.list-icons-reverse li {
	text-align: right;
	padding-right: 25px;
	padding-left: 0;
}

.list.list-icons.list-icons-reverse li > .fa:first-child,
.list.list-icons.list-icons-reverse li > .icons:first-child, .list.list-icons.list-icons-reverse li a:first-child > .fa:first-child,
.list.list-icons.list-icons-reverse li a:first-child > .icons:first-child {
	position: absolute;
	left: auto;
	right: 0;
	top: 5px;
}

.list.list-icons.list-icons-reverse.list-icons-style-2 li {
	padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-style-3 li {
	padding-right: 36px;
}

.list.list-icons.list-icons-reverse.list-icons-sm li {
	padding-left: 0;
	padding-right: 13px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-2 li {
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-sm.list-icons-style-3 li {
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg li {
	padding-left: 0;
	padding-right: 32px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-2 li {
	padding-right: 42px;
}

.list.list-icons.list-icons-reverse.list-icons-lg.list-icons-style-3 li {
	padding-right: 42px;
}

.list.list-borders li {
	border-bottom: 1px solid #DBDBDB;
	margin-top: 9px;
	margin-bottom: 0;
	padding-bottom: 9px;
}

.list.list-borders li:last-child {
	border-bottom: 0;
}

.list.list-side-borders {
	border-left: 1px solid #DBDBDB;
	padding-left: 10px;
}

.list.list-ordened {
	counter-reset: custom-counter;
	list-style: none;
}

.list.list-ordened li {
	position: relative;
	padding-left: 22px;
}

.list.list-ordened li:before {
	position: absolute;
	top: 0;
	left: 0;
	content: counter(custom-counter);
	counter-increment: custom-counter;
	font-weight: bold;
}

.list.list-ordened.list-ordened-style-2 li, .list.list-ordened.list-ordened-style-3 li {
	padding-left: 32px;
}

.list.list-ordened.list-ordened-style-2 li:before, .list.list-ordened.list-ordened-style-3 li:before {
	border-radius: 100%;
	border-style: solid;
	border-width: 1px;
	min-width: 26px;
	padding: 0;
	text-align: center;
}

.list.list-ordened.list-ordened-style-3 li:before {
	color: #FFF;
	border: 0;
}

.list.list-unstyled {
	padding-left: 0;
	padding-right: 0;
}

dl dd {
	margin-bottom: 15px;
}

/* Sitemap */
ul.sitemap > li > a {
	font-weight: bold;
}

ul.sitemap.list li {
	margin: 0 0 3px 0;
}

ul.sitemap.list ul {
	margin-bottom: 5px;
	margin-top: 5px;
}

/* Accordion */
.panel-group .panel-heading {
	padding: 0;
	border-radius: 3px;
}

.panel-group .panel-heading a {
	display: block;
	padding: 10px 15px;
}

.panel-group .panel-heading a:hover, .panel-group .panel-heading a:focus {
	text-decoration: none;
}

.panel-group .panel-heading a [class^="icon-"] {
	margin-right: 4px;
	position: relative;
	top: 1px;
}

.panel-group .panel-heading a .fa, .panel-group .panel-heading a .icons {
	display: inline-block;
	margin-right: 5px;
	position: relative;
	top: -1px;
}

.panel-group.panel-group-sm .panel-heading a {
	padding: 5px 15px;
	font-size: 0.9em;
}

.panel-group.panel-group-sm .panel-body {
	font-size: 0.9em;
}

.panel-group.panel-group-lg .panel-heading a {
	padding: 15px 15px;
	font-size: 1em;
	font-weight: 600;
}

.panel-group.without-bg .panel-default {
	background: transparent !important;
}

.panel-group.without-bg .panel-default > .panel-heading {
	background: transparent !important;
}

.panel-group.without-bg .panel-body {
	padding-top: 0;
	padding-bottom: 0;
}

.panel-group.without-borders .panel {
	border: 0;
	box-shadow: none;
	border-bottom: 1px solid #DDD;
	border-radius: 0;
}

.panel-group.without-borders .panel-heading a {
	padding-left: 0;
	padding-right: 0;
}

.panel-group.without-borders .panel-body {
	padding: 0;
}

/* Tabs */
.tabs {
	border-radius: 4px;
	margin-bottom: 20px;
}

.nav-tabs {
	margin: 0;
	padding: 0;
	font-size: 0;
	border-bottom-color: #EEE;
}

.nav-tabs li {
	display: inline-block;
	float: none;
}

.nav-tabs li:last-child a {
	margin-right: 0;
}

.nav-tabs li a {
	border-radius: 5px 5px 0 0;
	font-size: 14px;
	margin-right: 1px;
}

.nav-tabs li a, .nav-tabs li a:hover {
	background: #F4F4F4;
	border-bottom: none;
	border-left: 1px solid #EEE;
	border-right: 1px solid #EEE;
	border-top: 3px solid #EEE;
	color: #CCC;
}

.nav-tabs li a:hover {
	border-bottom-color: transparent;
	border-top: 3px solid #CCC;
	box-shadow: none;
}

.nav-tabs li a:active, .nav-tabs li a:focus {
	border-bottom: 0;
}

.nav-tabs li.active a,
.nav-tabs li.active a:hover,
.nav-tabs li.active a:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top: 3px solid #CCC;
	color: #CCC;
}

.tab-content {
	border-radius: 0 0 4px 4px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	background-color: #FFF;
	border: 1px solid #EEE;
	border-top: 0;
	padding: 15px;
}

/* Right Aligned */
.nav-tabs.nav-right {
	text-align: right;
}

/* Bottom Tabs */
.tabs.tabs-bottom {
	margin: 0 0 20px 0;
	padding: 0;
}

.tabs.tabs-bottom .tab-content {
	border-radius: 4px 4px 0 0;
	border-bottom: 0;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs {
	border-bottom: none;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li {
	margin-bottom: 0;
	margin-top: -1px;
}

.tabs.tabs-bottom .nav-tabs li:last-child a {
	margin-right: 0;
}

.tabs.tabs-bottom .nav-tabs li a {
	border-radius: 0 0 5px 5px;
	font-size: 14px;
	margin-right: 1px;
}

.tabs.tabs-bottom .nav-tabs li a, .tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom: 3px solid #EEE;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom: 3px solid #CCC;
	border-top: 1px solid #EEE;
}

.tabs.tabs-bottom .nav-tabs li.active a,
.tabs.tabs-bottom .nav-tabs li.active a:hover,
.tabs.tabs-bottom .nav-tabs li.active a:focus {
	border-bottom: 3px solid #CCC;
	border-top-color: transparent;
}

/* Vertical */
.tabs-vertical {
	display: table;
	width: 100%;
	padding: 0;
	border-top: 1px solid #EEE;
}

.tabs-vertical .tab-content {
	display: table-cell;
	vertical-align: top;
}

.tabs-vertical .nav-tabs {
	border-bottom: none;
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	vertical-align: top;
}

.tabs-vertical .nav-tabs > li {
	display: block;
}

.tabs-vertical .nav-tabs > li a {
	border-radius: 0;
	display: block;
	padding-top: 10px;
}

.tabs-vertical .nav-tabs > li a, .tabs-vertical .nav-tabs > li a:hover, .tabs-vertical .nav-tabs > li a:focus {
	border-bottom: none;
	border-top: none;
}

.tabs-vertical .nav-tabs > li.active a,
.tabs-vertical .nav-tabs > li.active a:hover, .tabs-vertical .nav-tabs > li.active:focus {
	border-top: none;
}

/* Vertical - Left Side */
.tabs-left {
	padding: 0;
}

.tabs-left .tab-content {
	border-radius: 0 5px 5px 5px;
	border-left: none;
}

.tabs-left .nav-tabs > li {
	margin-right: -1px;
}

.tabs-left .nav-tabs > li:first-child a {
	border-radius: 5px 0 0 0;
}

.tabs-left .nav-tabs > li:last-child a {
	border-radius: 0 0 0 5px;
	border-bottom: 1px solid #eee;
}

.tabs-left .nav-tabs > li a {
	border-right: 1px solid #EEE;
	border-left: 3px solid #EEE;
	margin-right: 1px;
	margin-left: -3px;
}

.tabs-left .nav-tabs > li a:hover {
	border-left-color: #CCC;
}

.tabs-left .nav-tabs > li.active a,
.tabs-left .nav-tabs > li.active a:hover,
.tabs-left .nav-tabs > li.active a:focus {
	border-left: 3px solid #CCC;
	border-right-color: #FFF;
}

/* Vertical - Right Side */
.tabs-right {
	padding: 0;
}

.tabs-right .tab-content {
	border-radius: 5px 0 5px 5px;
	border-right: none;
}

.tabs-right .nav-tabs > li {
	margin-left: -1px;
}

.tabs-right .nav-tabs > li:first-child a {
	border-radius: 0 5px 0 0;
}

.tabs-right .nav-tabs > li:last-child a {
	border-radius: 0 0 5px 0;
	border-bottom: 1px solid #eee;
}

.tabs-right .nav-tabs > li a {
	border-right: 3px solid #EEE;
	border-left: 1px solid #EEE;
	margin-right: 1px;
	margin-left: 1px;
}

.tabs-right .nav-tabs > li a:hover {
	border-right-color: #CCC;
}

.tabs-right .nav-tabs > li.active a,
.tabs-right .nav-tabs > li.active a:hover,
.tabs-right .nav-tabs > li.active a:focus {
	border-right: 3px solid #CCC;
	border-left: 1px solid #FFF;
}

/* Justified */
.nav-tabs.nav-justified {
	padding: 0;
	margin-bottom: -1px;
}

.nav-tabs.nav-justified li {
	margin-bottom: 0;
}

.nav-tabs.nav-justified li:first-child a,
.nav-tabs.nav-justified li:first-child a:hover {
	border-radius: 5px 0 0 0;
}

.nav-tabs.nav-justified li:last-child a,
.nav-tabs.nav-justified li:last-child a:hover {
	border-radius: 0 5px 0 0;
}

.nav-tabs.nav-justified li a {
	border-bottom: 1px solid #DDD;
	border-radius: 0;
	margin-right: 0;
}

.nav-tabs.nav-justified li a:hover, .nav-tabs.nav-justified li a:focus {
	border-bottom: 1px solid #DDD;
}

.nav-tabs.nav-justified li.active a,
.nav-tabs.nav-justified li.active a:hover,
.nav-tabs.nav-justified li.active a:focus {
	background: #FFF;
	border-left-color: #EEE;
	border-right-color: #EEE;
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active a {
	border-bottom: 1px solid #FFF;
}

.nav-tabs.nav-justified li.active a, .nav-tabs.nav-justified li.active a:hover, .nav-tabs.nav-justified li.active a:focus {
	border-top-width: 3px;
}

.nav-tabs.nav-justified li.active a:hover {
	border-bottom: 1px solid #FFF;
}

/* Bottom Tabs with Justified Nav */
.tabs.tabs-bottom {
	padding: 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified {
	border-top: none;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li a {
	margin-right: 0;
	border-top-color: transparent;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a {
	border-radius: 0 0 0 5px;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a {
	margin-right: 0;
	border-radius: 0 0 5px 0;
}

.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a:hover,
.tabs.tabs-bottom .nav.nav-tabs.nav-justified li.active a:focus {
	border-top-color: transparent;
}

/* Center */
.tabs-center .nav-tabs {
	margin: 0 auto;
	text-align: center;
}

/* Navigation */
.tabs-navigation {
	border-radius: 5px;
}

.tabs-navigation .nav-tabs > li {
	margin-bottom: 1px;
}

.tabs-navigation .nav-tabs > li a {
	color: #777;
	padding: 18px;
	line-height: 100%;
}

.tabs-navigation .nav-tabs > li a:before {
	font-family: FontAwesome;
	content: "\f054";
	position: absolute;
	right: 15px;
	top: 50%;
	margin-top: -7px;
}

.tabs-navigation .nav-tabs > li a .fa, .tabs-navigation .nav-tabs > li a .icons {
	margin-right: 4px;
}

.tabs-navigation .nav-tabs > li:first-child a {
	border-radius: 5px 5px 0 0;
}

.tabs-navigation .nav-tabs > li.active a, .tabs-navigation .nav-tabs > li.active a:hover, .tabs-navigation .nav-tabs > li.active a:focus {
	border-right-color: #eee;
}

.tabs-navigation .nav-tabs > li:last-child a {
	border-radius: 0 0 5px 5px;
}

.tab-pane-navigation {
	display: none;
}

.tab-pane-navigation.active {
	display: block;
}

/* Simple */
.tabs.tabs-simple .nav-tabs {
	border: 0;
	margin-bottom: 10px;
}

.tabs.tabs-simple .nav-tabs > li {
	margin-left: 0;
	margin-bottom: 20px;
}

.tabs.tabs-simple .nav-tabs > li a, .tabs.tabs-simple .nav-tabs > li a:hover, .tabs.tabs-simple .nav-tabs > li a:focus {
	padding: 15px 30px;
	background: none;
	border: 0;
	border-bottom: 3px solid #eee;
	border-radius: 0;
	color: #777;
	font-size: 16px;
}

.tabs.tabs-simple .tab-content {
	background: transparent;
	border: 0;
	padding: 0;
	margin: 0;
	box-shadow: none;
}

/* Responsive */
@media (max-width: 479px) {
	.tabs .nav.nav-tabs.nav-justified li {
		display: block;
		margin-bottom: -5px;
	}

	.tabs .nav.nav-tabs.nav-justified li a {
		border-top-width: 3px !important;
		border-bottom-width: 0 !important;
	}

	.tabs .nav.nav-tabs.nav-justified li:first-child a, .tabs .nav.nav-tabs.nav-justified li:first-child a:hover {
		border-radius: 5px 5px 0 0;
	}

	.tabs .nav.nav-tabs.nav-justified li:last-child a, .tabs .nav.nav-tabs.nav-justified li:last-child a:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li {
		margin-bottom: 0;
		margin-top: -5px;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li a {
		border-bottom-width: 3px !important;
		border-top-width: 0 !important;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:first-child a:hover {
		border-radius: 0;
	}

	.tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a, .tabs.tabs-bottom .nav.nav-tabs.nav-justified li:last-child a:hover {
		border-radius: 0 0 5px 5px;
	}
}
/* Toggles */
.toggle {
	margin: 10px 0 20px;
	position: relative;
	clear: both;
}

.toggle .toggle {
	margin: 10px 0 0;
}

.toggle > input {
	cursor: pointer;
	filter: alpha(opacity=0);
	height: 45px;
	margin: 0;
	opacity: 0;
	position: absolute;
	width: 100%;
	z-index: 2;
}

.toggle > label {
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
	background: #F4F4F4;
	border-left: 3px solid #CCC;
	border-radius: 5px;
	color: #CCC;
	display: block;
	font-size: 1.1em;
	min-height: 20px;
	padding: 12px 20px 12px 10px;
	position: relative;
	cursor: pointer;
	font-weight: 400;
}

.toggle > label:-moz-selection {
	background: none;
}

.toggle > label i.fa-minus {
	display: none;
}

.toggle > label i.fa-plus {
	display: inline;
}

.toggle > label:selection {
	background: none;
}

.toggle > label:before {
	border: 6px solid transparent;
	border-left-color: inherit;
	content: '';
	margin-top: -6px;
	position: absolute;
	right: 4px;
	top: 50%;
}

.toggle > label:hover {
	background: #f5f5f5;
}

.toggle > label + p {
	color: #999;
	display: block;
	overflow: hidden;
	padding-left: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 25px;
}

.toggle > label i {
	font-size: 0.7em;
	margin-right: 8px;
	position: relative;
	top: -1px;
}

.toggle > .toggle-content {
	display: none;
}

.toggle > .toggle-content > p {
	margin-bottom: 0;
	padding: 10px 0;
}

.toggle.active i.fa-minus {
	display: inline;
}

.toggle.active i.fa-plus {
	display: none;
}

.toggle.active > label {
	background: #F4F4F4;
	border-color: #CCC;
}

.toggle.active > label:before {
	border: 6px solid transparent;
	border-top-color: #FFF;
	margin-top: -3px;
	right: 10px;
}

.toggle.active > p {
	white-space: normal;
}

.toggle > p.preview-active {
	height: auto;
	white-space: normal;
}

.toggle.toggle-sm .toggle > label {
	font-size: 0.9em;
	padding: 6px 20px 6px 10px;
}

.toggle.toggle-sm .toggle > .toggle-content > p {
	font-size: 0.9em;
}

.toggle.toggle-lg .toggle > label {
	font-size: 1.1em;
	padding: 16px 20px 16px 20px;
}

.toggle.toggle-lg .toggle > .toggle-content > p {
	font-size: 1.1em;
}

.toggle.toggle-simple .toggle > label {
	border-radius: 0;
	border: 0;
	background: transparent;
	color: #777;
	padding-left: 32px;
	margin-bottom: -10px;
	font-size: 1em;
	font-weight: 600;
	color: #1d2127;
}

.toggle.toggle-simple .toggle > label:after {
	content: '';
	position: absolute;
	left: 0;
	display: block;
	width: 24px;
	height: 24px;
	background: #CCC;
	top: 23px;
	margin-top: -12px;
	border-radius: 3px;
	z-index: 1;
}

.toggle.toggle-simple .toggle > label:before {
	border: 0;
	left: 7px;
	right: auto;
	margin-top: 0;
	top: 12px;
	font-family: FontAwesome;
	content: "\f067";
	z-index: 2;
	color: #FFF;
	font-size: 13px;
}

.toggle.toggle-simple .toggle.active > label {
	background: transparent;
	color: #1d2127;
}

.toggle.toggle-simple .toggle.active > label:before {
	content: "\f068";
}

/* Owl Carousel */
.owl-carousel {
	margin-bottom: 20px;
}

.owl-carousel .owl-item img {
	transform-style: unset;
}

.owl-carousel .thumbnail {
	margin-right: 1px;
}

.owl-carousel .item-video {
	height: 300px;
}

.owl-carousel .owl-nav {
	top: 50%;
	position: absolute;
	margin-top: -20px;
	width: 100%;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
	display: inline-block;
	position: absolute;
	width: 30px;
	height: 30px;
}

.owl-carousel .owl-nav .owl-prev {
	left: -5px;
}

.owl-carousel .owl-nav .owl-prev:before {
	font-family: FontAwesome;
	content: "\f053";
	position: relative;
	left: -1px;
	top: 1px;
}

.owl-carousel .owl-nav .owl-next {
	right: -5px;
}

.owl-carousel .owl-nav .owl-next:before {
	font-family: FontAwesome;
	content: "\f054";
	position: relative;
	left: 2px;
	top: 1px;
}

.owl-carousel.stage-margin .owl-stage-outer {
	margin-left: 40px !important;
	margin-right: 40px !important;
}

.owl-carousel.stage-margin .owl-stage-outer .owl-stage {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.owl-carousel.show-nav-hover .owl-nav {
	opacity: 0;
	-webkit-transition: opacity 0.2s ease-in-out;
	-moz-transition: opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
}

.owl-carousel.show-nav-hover .owl-nav .owl-prev {
	left: -20px;
}

.owl-carousel.show-nav-hover .owl-nav .owl-next {
	right: -20px;
}

.owl-carousel.show-nav-hover:hover .owl-nav {
	opacity: 1;
}

.owl-carousel.show-nav-title .owl-nav {
	top: 0;
	right: 0;
	margin-top: -45px;
	width: auto;
}

.owl-carousel.show-nav-title .owl-nav [class*="owl-"], .owl-carousel.show-nav-title .owl-nav [class*="owl-"]:hover, .owl-carousel.show-nav-title .owl-nav [class*="owl-"]:active {
	font-size: 18px;
	background: transparent !important;
	width: 18px;
	font-size: 18px;
}

.owl-carousel.show-nav-title .owl-nav .owl-prev {
	left: -45px;
}

.owl-carousel.rounded-nav .owl-nav [class*="owl-"], .owl-carousel.rounded-nav .owl-nav [class*="owl-"]:hover {
	padding: 3px 7px;
	border-radius: 50%;
	background: transparent;
	border: 1px solid #999 !important;
	color: #999;
}

.owl-carousel.nav-bottom .owl-nav {
	position: static;
	margin: 0;
	padding: 0;
	width: auto;
}

.owl-carousel.nav-bottom .owl-nav .owl-prev, .owl-carousel.nav-bottom .owl-nav .owl-next {
	position: static;
}

.owl-carousel.nav-inside .owl-nav, .owl-carousel.nav-inside .owl-dots {
	bottom: 2px;
	margin-top: 10px;
	position: absolute;
	right: 6px;
}

.owl-carousel.full-width .owl-nav, .owl-carousel.big-nav .owl-nav {
	margin-top: -30px;
}

.owl-carousel.full-width .owl-nav [class*="owl-"], .owl-carousel.full-width .owl-nav [class*="owl-"]:hover, .owl-carousel.big-nav .owl-nav [class*="owl-"], .owl-carousel.big-nav .owl-nav [class*="owl-"]:hover {
	height: auto;
	padding: 20px 0;
}

.owl-carousel.full-width .owl-nav .owl-prev, .owl-carousel.big-nav .owl-nav .owl-prev {
	border-radius: 0 4px 4px 0;
}

.owl-carousel.full-width .owl-nav .owl-next, .owl-carousel.big-nav .owl-nav .owl-next {
	border-radius: 4px 0 0 4px;
}

.owl-carousel.top-border {
	border-top: 1px solid #DBDBDB;
	padding-top: 18px;
}

/* Spaced */
.owl-carousel-spaced {
	margin-left: -5px;
}

.owl-carousel-spaced .owl-item > div {
	margin: 5px;
}

/* Testimonials */
.owl-carousel.testimonials img {
	display: inline-block;
	height: 70px;
	width: 70px;
}

/* Responsive */
@media (max-width: 479px) {
	.owl-carousel-spaced {
		margin-left: 0;
	}
}
/* Carousel Areas */
.carousel-areas {
	background: transparent url(../img/patterns/stripes.png) repeat 0 0;
	margin-bottom: -10px;
	padding: 10px 10px 0 10px;
	border-radius: 6px 6px 0 0;
}

.carousel-areas .owl-carousel {
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.carousel-areas .owl-carousel .owl-nav .owl-prev {
	left: -55px;
}

.carousel-areas .owl-carousel .owl-nav .owl-next {
	right: -55px;
}

.carousel-areas .owl-carousel:first-child img {
	border-radius: 6px 6px 0 0;
}

/* Responsive */
@media (max-width: 991px) {
	.carousel-areas .owl-carousel .owl-nav {
		display: none;
	}
}
/* Video */
section.video {
	-webkit-transform: translate3d(0, 0, 0);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding: 50px 0;
	position: relative;
	overflow: hidden;
	width: 100%;
}

section.video .container {
	position: relative;
	z-index: 3;
}

section.video .video-overlay {
	background: transparent url(../img/video-overlay.png) repeat 0 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

section.video.video-overlay-dark .video-overlay {
	background-image: url(../img/video-overlay-dark.png);
}

html.boxed section.video > div:first-child {
	z-index: 0 !important;
}

html.boxed section.video > div:first-child video {
	z-index: 0 !important;
}

/* Popup */
.popup-inline-content,
.mfp-content .ajax-container {
	background: none repeat scroll 0 0 #FFF;
	margin: 40px auto;
	max-width: 1170px;
	padding: 20px 30px;
	position: relative;
	text-align: left;
	display: none;
}

.mfp-content .ajax-container {
	display: block;
}

.mfp-wrap .popup-inline-content {
	display: block;
}

/* Close */
.mfp-close,
.mfp-close-btn-in .mfp-close {
	font-family: "Open Sans", Arial, sans-serif;
	font-weight: 600;
	font-size: 22px;
	color: #838383;
}

/* No Margins */
.mfp-no-margins img.mfp-img {
	padding: 0;
}

.mfp-no-margins .mfp-figure:after {
	top: 0;
	bottom: 0;
}

.mfp-no-margins .mfp-container {
	padding: 0;
}

/* Zoom */
.mfp-with-zoom .mfp-container, .mfp-with-zoom.mfp-bg {
	opacity: 0.001;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container, .mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

/* Animnate */
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0;
}

.my-mfp-zoom-in.mfp-bg {
	opacity: 0.001;
	/* Chrome opacity transition bug */
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}

.my-mfp-slide-bottom .zoom-anim-dialog {
	opacity: 0;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	-webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	-moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	-ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	-o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
	transform: translateY(-20px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: translateY(0) perspective(600px) rotateX(0);
	-moz-transform: translateY(0) perspective(600px) rotateX(0);
	-ms-transform: translateY(0) perspective(600px) rotateX(0);
	-o-transform: translateY(0) perspective(600px) rotateX(0);
	transform: translateY(0) perspective(600px) rotateX(0);
}

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
	opacity: 0;
	-webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	-moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	-ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	-o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
	transform: translateY(-10px) perspective(600px) rotateX(10deg);
}

.my-mfp-slide-bottom.mfp-bg {
	opacity: 0.01;
	-webkit-transition: opacity 0.3s ease-out;
	-moz-transition: opacity 0.3s ease-out;
	-o-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
}

.my-mfp-slide-bottom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.my-mfp-slide-bottom.mfp-removing.mfp-bg {
	opacity: 0;
}

/* Fade */
.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}

/* Dialog */
.dialog {
	background: white;
	padding: 20px 30px;
	text-align: left;
	margin: 40px auto;
	position: relative;
	max-width: 600px;
}

.dialog.dialog-xs {
	max-width: 200px;
}

.dialog.dialog-sm {
	max-width: 400px;
}

.dialog.dialog-md {
	max-width: 600px;
}

.dialog.dialog-lg {
	max-width: 900px;
}

/* White Popup Block */
.white-popup-block {
	background: #FFF;
	padding: 20px 30px;
	text-align: left;
	max-width: 600px;
	margin: 40px auto;
	position: relative;
}

.white-popup-block.white-popup-block-xs {
	max-width: 200px;
}

.white-popup-block.white-popup-block-sm {
	max-width: 400px;
}

.white-popup-block.white-popup-block-md {
	max-width: 600px;
}

.white-popup-block.white-popup-block-lg {
	max-width: 900px;
}

/* Lightbox Opened */
html.lightbox-opened.sticky-header-active #header .header-body {
	padding-right: 17px;
}

/* Modal Opened */
.modal-open {
	padding-right: 0 !important;
}

/* Grid */
.col-half-section {
	width: 100%;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
	max-width: 570px;
	float: left;
	margin-left: 30px;
	margin-right: 0;
}

.col-half-section.col-half-section-right {
	float: right;
	margin-left: 0;
	margin-right: 30px;
}

@media (max-width: 1199px) {
	.col-half-section {
		max-width: 468px;
	}
}

@media (max-width: 991px) {
	.col-half-section {
		max-width: 740px;
		float: none !important;
		margin: 0 auto !important;
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
}
/* Miscellaneous */
.pull-none {
	float: none !important;
}

.pull-left {
	float: left !important;
}

.pull-right {
	float: right !important;
}

@media (max-width: 767px) {
	.pull-xs-left {
		float: left;
	}

	.pull-xs-right {
		float: right;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.pull-sm-left {
		float: left;
	}

	.pull-sm-right {
		float: right;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.pull-md-left {
		float: left;
	}

	.pull-md-right {
		float: right;
	}
}

@media (min-width: 1200px) {
	.pull-lg-left {
		float: left;
	}

	.pull-lg-right {
		float: right;
	}
}

.align-center, .center {
	text-align: center !important;
}

.align-left {
	text-align: left !important;
}

.align-right {
	text-align: right !important;
}

.bold {
	font-weight: bold;
}

.semi-bold {
	font-weight: 600;
}

.inverted {
	color: #FFF;
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
}

h1 .inverted {
	padding-left: 10px;
	padding-right: 10px;
}

h2 .inverted {
	padding-left: 7px;
	padding-right: 7px;
}

h3 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h4 .inverted {
	padding-left: 4px;
	padding-right: 4px;
}

h5 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h6 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

/* Cursor */
.cur-pointer {
	cursor: pointer;
}

/* Font Size */
.font-size-xs {
	font-size: 0.8em !important;
}

.font-size-sm {
	font-size: 0.9em !important;
}

.font-size-md {
	font-size: 1em !important;
}

.font-size-lg {
	font-size: 1.1em !important;
}

.font-size-xl {
	font-size: 1.2em !important;
}

/* Line Height */
.line-height-xs {
	line-height: 16px !important;
}

.line-height-sm {
	line-height: 20px !important;
}

.line-height-md {
	line-height: 24px !important;
}

.line-height-lg {
	line-height: 28px !important;
}

.line-height-xl {
	line-height: 32px !important;
}

/* Outline */
.outline-none {
	outline: 0 !important;
}

/* Text Decoration */
.text-decoration-none {
	text-decoration: none !important;
}

/* Display */
.display-block {
	display: block !important;
}

.display-inline-block {
	display: inline-block !important;
}

.display-inline {
	display: inline !important;
}

/* Block Link */
.block-link {
	display: block !important;
}

/* No Borders */
.no-borders {
	border: none !important;
}

/* Clearfix */
.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .clearfix {
	display: block;
}

* html .clearfix {
	height: 1%;
}

/* Container */
.container-xl {
	width: 100%;
	max-width: 1630px;
}

/* Sample Item */
.sample-item-container {
	max-width: 1648px;
}

.sample-item-list {
	list-style: none;
	padding: 0;
	margin: 0;
	opacity: 0;
}

.sample-item-list li {
	text-align: center;
}

.sample-item-list.sample-item-list-loaded {
	opacity: 1;
}

.sample-item {
	text-align: center;
	max-width: 500px;
	width: 100%;
	display: inline-block;
	margin-bottom: 35px;
}

.sample-item a {
	text-decoration: none !important;
}

.sample-item .owl-carousel {
	margin-bottom: 0;
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.1);
	position: relative;
	height: 22.8vw;
	max-height: 365px;
	display: block !important;
	overflow: hidden;
	-webkit-transition: box-shadow 0.3s ease;
	-moz-transition: box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease;
}

.sample-item .owl-carousel:hover {
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
}

.sample-item .owl-carousel .sample-item-image-wrapper {
	box-shadow: none;
}

.sample-item .owl-carousel .owl-item > div {
	height: 365px;
	max-height: 365px;
}

.sample-item .owl-carousel .owl-nav .owl-prev,
.sample-item .owl-carousel .owl-nav .owl-next {
	border: 0 none;
	border-radius: 0 4px 4px 0;
	color: #777;
	height: 50px;
	line-height: 38px;
	margin-top: 0;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.sample-item .owl-carousel .owl-nav .owl-prev:hover, .sample-item .owl-carousel .owl-nav .owl-prev:focus,
.sample-item .owl-carousel .owl-nav .owl-next:hover,
.sample-item .owl-carousel .owl-nav .owl-next:focus {
	color: #000 !important;
}

.sample-item .owl-carousel .owl-nav .owl-prev:before,
.sample-item .owl-carousel .owl-nav .owl-next:before {
	font-size: 11px;
}

.sample-item .owl-carousel .owl-nav .owl-prev {
	background-color: #E7E7E7;
	-webkit-transform: translate3d(-30px, 0, 0);
	-moz-transform: translate3d(-30px, 0, 0);
	-ms-transform: translate3d(-30px, 0, 0);
	-o-transform: translate3d(-30px, 0, 0);
	transform: translate3d(-30px, 0, 0);
}

.sample-item .owl-carousel .owl-nav .owl-prev:hover, .sample-item .owl-carousel .owl-nav .owl-prev:focus {
	background-color: #E7E7E7 !important;
}

.sample-item .owl-carousel .owl-nav .owl-next {
	background-color: #E7E7E7;
	-webkit-transform: translate3d(30px, 0, 0);
	-moz-transform: translate3d(30px, 0, 0);
	-ms-transform: translate3d(30px, 0, 0);
	-o-transform: translate3d(30px, 0, 0);
	transform: translate3d(30px, 0, 0);
	border-radius: 4px 0 0 4px;
}

.sample-item .owl-carousel .owl-nav .owl-next:hover, .sample-item .owl-carousel .owl-nav .owl-next:focus {
	background-color: #E7E7E7 !important;
}

.sample-item .owl-carousel:hover .owl-nav .owl-prev {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.sample-item .owl-carousel:hover .owl-nav .owl-next {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.sample-item .sample-item-image-wrapper {
	background: #FFF;
	height: 22vw;
	max-height: 365px;
	max-width: 500px;
	width: 100%;
	display: inline-block;
	border: none;
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.1);
	position: relative;
	-webkit-transition: box-shadow 0.3s ease;
	-moz-transition: box-shadow 0.3s ease;
	transition: box-shadow 0.3s ease;
}

.sample-item .sample-item-image-wrapper .fa-spin {
	background: transparent none repeat scroll 0 0;
	color: #ccc;
	font-size: 25px;
	left: 50%;
	margin: -13px;
	position: absolute;
	top: 50%;
	z-index: 1;
}

.sample-item a:hover .sample-item-image-wrapper {
	box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.4);
}

.sample-item .sample-item-image {
	height: 22.8vw;
	max-height: 365px;
	max-width: 500px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 100% auto;
	z-index: 2;
}

.sample-item .sample-item-description {
	display: block;
	padding: 15px 0;
}

.sample-item h5 {
	padding: 0;
	margin: 0;
	font-size: 14px;
}

.sample-item h5 .sample-item-info {
	display: block;
	font-size: 11px;
	opacity: 0.6;
}

.sample-item p {
	padding: 0;
	margin: 0;
	font-size: 12px;
}

.sample-item.sample-item-home .sample-item-image-wrapper {
	height: 13.9vw;
}

.sample-item.sample-item-home .sample-item-image-wrapper,
.sample-item.sample-item-home .sample-item-image {
	max-height: 260px;
}

.sample-item.sample-item-coming-soon .sample-item-image {
	background-color: #FBFBFB;
	background-position: center center;
}

.sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
	height: 15vw;
}

@media (max-width: 991px) {
	.sample-item .sample-item-image-wrapper, .sample-item .owl-carousel {
		height: 33vw;
	}

	.sample-item .sample-item-image {
		height: 34vw;
	}

	.sample-item.sample-item-home .sample-item-image-wrapper {
		height: 30vw;
	}

	.sample-item.sample-item-home .sample-item-image {
		height: 30vw;
	}

	.sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
		height: 30vw;
	}
}

@media (max-width: 767px) {
	.sample-item .sample-item-image-wrapper, .sample-item .owl-carousel {
		height: 63.4vw;
	}

	.sample-item .sample-item-image {
		height: 67.5vw;
	}

	.sample-item.sample-item-home .sample-item-image-wrapper {
		height: 63.4vw;
		max-height: 345px;
	}

	.sample-item.sample-item-home .sample-item-image {
		height: 67.5vw;
		max-height: 345px;
	}

	.sample-item.sample-item-coming-soon.sample-item-home .sample-item-image {
		height: 63.4vw;
	}
}
/* Sample Sub Items Lightbox */
.sample-sub-items-lightbox {
	margin: 0 auto;
	max-width: 1650px;
	padding: 10vh 10px 50px;
	width: 100%;
}

/* Magnific Popup Demo Options */
.mfp-demo-options.mfp-bg {
	opacity: 0.98 !important;
	background: #F4F4F4;
}

.mfp-demo-options.mfp-bg.mfp-removing {
	opacity: 0 !important;
}

.mfp-demo-options .mfp-close {
	position: fixed;
	top: 0;
	right: 17px;
	z-index: 3000;
}

.mfp-demo-options.mfp-wrap {
	height: 100vh !important;
}

.mfp-demo-options.mfp-wrap:before {
	background: -webkit-linear-gradient( top , #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	background: linear-gradient(to bottom, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	z-index: 2000;
	opacity: 1;
	opacity: 0.7;
}

.mfp-demo-options.mfp-wrap:after {
	background: -webkit-linear-gradient( bottom , #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	background: linear-gradient(to top, #f4f4f4 0, transparent 100%) repeat scroll 0 0 rgba(244, 244, 244, 0);
	content: '';
	display: block;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	z-index: 2000;
	opacity: 1;
	opacity: 0.7;
}

.mfp-demo-options.mfp-wrap.mfp-removing:before, .mfp-demo-options.mfp-wrap.mfp-removing:after {
	opacity: 0 !important;
}

/* Pagination */
.pagination {
	position: relative;
	z-index: 1;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	color: #CCC;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #CCC;
	border-color: #CCC;
}

/* Read More */
.read-more,
.learn-more {
	display: inline-block;
	white-space: nowrap;
}

/* Offset Anchor */
.offset-anchor {
	display: block;
	position: relative;
	visibility: hidden;
	top: -100px;
}

/* Responsive */
@media (max-width: 991px) {
	.offset-anchor {
		top: 0;
	}
}
/* Show Grid */
.show-grid [class*="col-md-"] .show-grid-block {
	background-color: #EEE;
	line-height: 40px;
	min-height: 40px;
	text-align: center;
	border: 1px solid #FFF;
	display: block;
}

/* Alerts */
.alert .fa, .alert .icons {
	margin-right: 8px;
	font-size: 1.1em;
}

.alert ul {
	padding: 0;
	margin: 7px 0 0 40px;
}

.alert ul li {
	font-size: 0.9em;
}

.alert.alert-sm {
	padding: 5px 10px;
	font-size: 0.9em;
}

.alert.alert-lg {
	padding: 20px;
	font-size: 1.2em;
}

.alert.alert-default {
	background-color: #f2f2f2;
	border-color: #eaeaea;
	color: #737373;
}

.alert.alert-default .alert-link {
	color: #4c4c4c;
}

.alert.alert-dark {
	background-color: #333a44;
	border-color: #07080a;
	color: #ced3db;
}

.alert.alert-dark .alert-link {
	color: #fafbfb;
}

/* Embed Responsive */
.embed-responsive.embed-soundcloud {
	padding-bottom: 19.25%;
}

.embed-responsive.embed-responsive-borders {
	border: 1px solid #DDD;
	padding: 4px;
	border-radius: 4px;
}

/* Content Grid */
.content-grid {
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.content-grid .content-grid-item {
	position: relative;
	padding: 30px;
	margin: 0;
}

.content-grid .content-grid-item:before, .content-grid .content-grid-item:after {
	content: '';
	position: absolute;
}

.content-grid .content-grid-item:before {
	height: 100%;
	top: 0;
	left: -1px;
	border-left: 1px solid #DADADA;
}

.content-grid .content-grid-item:after {
	width: 100%;
	height: 0;
	top: auto;
	left: 0;
	bottom: -1px;
	border-bottom: 1px solid #DADADA;
}

.content-grid .content-grid-item .img-responsive {
	display: inline-block;
}

.content-grid.content-grid-dashed .content-grid-item:before {
	border-left: 1px dashed #DADADA;
}

.content-grid.content-grid-dashed .content-grid-item:after {
	border-bottom: 1px dashed #DADADA;
}

/* Alert Admin */
.alert-admin {
	margin: 25px 0;
}

.alert-admin img {
	margin: -50px 0 0;
}

.alert-admin .btn {
	margin: 0 0 -15px;
}

.alert-admin p {
	color: #444;
}

.alert-admin h4 {
	color: #111;
	font-size: 1.2em;
	font-weight: 600;
	text-transform: uppercase;
}

.alert-admin .warning {
	color: #B20000;
}

/* Not Included */
.not-included {
	color: #b7b7b7;
	display: block;
	font-size: 0.8em;
	font-style: normal;
	margin: -4px 0;
	padding: 0;
}

/* Tip */
.tip {
	display: inline-block;
	padding: 0 5px;
	background: #FFF;
	color: #111;
	text-shadow: none;
	border-radius: 3px;
	margin-left: 8px;
	position: relative;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: bold;
}

.tip:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 5px;
	margin-top: -5px;
}

.tip.skin {
	color: #FFF;
}

/* Custom Underline */
.custom-underline {
	background: url(../img/custom-underline.png) no-repeat center 100%;
	padding-bottom: 25px;
	font-size: 1.5em;
}

/* Ajax Box */
.ajax-box {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	position: relative;
}

.ajax-box .ajax-box-content {
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	transition: all 0.2s;
	opacity: 1;
	overflow: hidden;
	height: auto;
}

.ajax-box .bounce-loader {
	opacity: 0;
}

.ajax-box.ajax-box-init {
	height: 0;
}

.ajax-box.ajax-box-loading {
	height: 300px;
}

.ajax-box.ajax-box-loading .ajax-box-content {
	opacity: 0;
	height: 300px;
}

.ajax-box.ajax-box-loading .bounce-loader {
	opacity: 1;
}

/* Go to Demos */
.go-to-demos {
	-webkit-transition: padding 0.3s ease;
	-moz-transition: padding 0.3s ease;
	transition: padding 0.3s ease;
	background-color: #EEE;
	border-radius: 0 6px 6px 0;
	color: #888 !important;
	display: block;
	font-size: 10px;
	height: 40px;
	left: 0;
	line-height: 38px;
	padding: 0 15px 0 10px;
	position: fixed;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	top: 90px;
	width: auto;
	z-index: 999;
}

.go-to-demos .fa {
	-webkit-transition: left 0.3s ease;
	-moz-transition: left 0.3s ease;
	transition: left 0.3s ease;
	display: inline-block;
	padding-right: 5px;
	position: relative;
	left: 0;
}

.go-to-demos:hover {
	text-decoration: none;
	padding-left: 25px;
}

.go-to-demos:hover .fa {
	left: -7px;
}

@media (max-width: 991px) {
	.go-to-demos {
		display: none;
	}
}
/* Dividers */
hr {
	background-image: -webkit-linear-gradient(left, transparent, #DBDBDB, transparent);
	background-image: linear-gradient(to right, transparent, #DBDBDB, transparent);
	border: 0;
	height: 1px;
	margin: 22px 0;
}

hr.short {
	margin: 11px 0;
}

hr.tall {
	margin: 44px 0;
}

hr.taller {
	margin: 66px 0;
}

hr.gradient-to-right {
	background-image: -webkit-linear-gradient(left, #DBDBDB, transparent);
	background-image: linear-gradient(to right, #DBDBDB, transparent);
}

hr.gradient-to-left {
	background-image: -webkit-linear-gradient(left, transparent, #DBDBDB);
	background-image: linear-gradient(to right, transparent, #DBDBDB);
}

hr.solid {
	background: #DBDBDB;
}

hr.light {
	background-image: -webkit-linear-gradient(left, transparent, rgba(255, 255, 255, 0.15), transparent);
	background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.15), transparent);
}

hr.invisible {
	background: none;
}

.divider {
	border: 0;
	height: 1px;
	margin: 44px auto;
	background-image: -webkit-linear-gradient(left, transparent, #DBDBDB, transparent);
	background-image: linear-gradient(to right, transparent, #DBDBDB, transparent);
	text-align: center;
	position: relative;
	clear: both;
}

.divider.taller {
	margin: 66px auto;
}

.divider .fa, .divider .icons {
	text-align: center;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	background: #FFF;
	border-radius: 50px;
	color: #a9a9a9;
	display: inline-block;
	height: 50px;
	line-height: 50px;
	position: absolute;
	text-align: center;
	width: 50px;
	font-size: 20px;
	margin: 0 auto 0 -25px;
	top: -25px;
	left: 50%;
	z-index: 1;
}

.divider.divider-solid {
	background: #DBDBDB;
}

.divider.divider-icon-sm .fa, .divider.divider-icon-sm .icons {
	width: 30px;
	height: 30px;
	line-height: 30px;
	top: -15px;
	font-size: 15px;
	margin-left: -16px;
}

.divider.divider-icon-lg {
	margin: 86px 0;
}

.divider.divider-icon-lg .fa, .divider.divider-icon-lg .icons {
	width: 60px;
	height: 60px;
	line-height: 60px;
	top: -30px;
	font-size: 30px;
	margin-left: -30px;
}

.divider.divider-icon-lg.taller {
	margin: 100px auto;
}

.divider.divider-xs {
	width: 35%;
}

.divider.divider-sm {
	width: 67%;
}

.divider.divider-left .fa, .divider.divider-left .icons {
	left: 0;
	margin-left: 0;
	margin-right: 0;
	right: auto;
}

.divider.divider-right .fa, .divider.divider-right .icons {
	left: auto;
	margin-left: 0;
	margin-right: 0;
	right: 0;
}

.divider.divider-style-2 .fa, .divider.divider-style-2 .icons {
	background: #f4f4f4;
	color: #a9a9a9;
}

.divider.divider-style-3 .fa, .divider.divider-style-3 .icons {
	border: 1px solid #CECECE;
}

.divider.divider-style-4 .fa, .divider.divider-style-4 .icons {
	border: 1px solid #CECECE;
}

.divider.divider-style-4 .fa:after, .divider.divider-style-4 .icons:after {
	border: 3px solid #f4f4f4;
	border-radius: 50%;
	box-sizing: content-box;
	content: "";
	display: block;
	height: 100%;
	left: -4px;
	padding: 1px;
	position: absolute;
	top: -4px;
	width: 100%;
}

.divider.divider-small {
	margin: 22px auto;
	background: transparent;
}

.divider.divider-small hr {
	width: 50px;
	height: 3px;
	background: #555;
}

.divider.divider-small.divider-small-center {
	text-align: center;
}

.divider.divider-small.divider-small-center hr {
	margin-left: auto;
	margin-right: auto;
}

.divider.divider-small.divider-small-right {
	text-align: right;
}

.divider.divider-small.divider-small-right hr {
	margin-left: auto;
}

.divider.divider-small.divider-light hr {
	background: #DDD;
}

.divider.divider-small.divider-small-sm hr {
	height: 1px;
}

.divider.divider-small.divider-small-lg hr {
	height: 6px;
}

.divider.divider-small.tall {
	margin: 34px 0;
}

.divider.divider-small.taller {
	margin: 46px 0;
}

hr.dashed, .divider.dashed {
	background: none;
	position: relative;
}

hr.dashed:after, .divider.dashed:after {
	border-top: 1px dashed #DBDBDB;
	content: "";
	display: block;
	height: 0;
	position: absolute;
	top: 50%;
	margin-top: -1px;
	width: 100%;
}

hr.pattern, .divider.pattern {
	background: none;
	position: relative;
}

hr.pattern:after, .divider.pattern:after {
	background: transparent url(../img/patterns/worn_dots.png) repeat 0 0;
	content: "";
	display: block;
	height: 15px;
	position: absolute;
	top: 50%;
	margin-top: -7px;
	width: 100%;
}

hr.pattern.pattern-2:after, .divider.pattern.pattern-2:after {
	background-image: url(../img/patterns/az_subtle.png);
}

/* Sort Source Wrapper */
.sort-source-wrapper {
	padding: 8px 0;
	position: relative;
}

.sort-source-wrapper .nav {
	margin: -100px 0 5px;
}

.sort-source-wrapper .nav > li > a {
	color: #FFF;
	background: transparent;
}

.sort-source-wrapper .nav > li > a:hover, .sort-source-wrapper .nav > li > a:focus {
	background: transparent;
}

.sort-source-wrapper .nav > li.active > a {
	background: transparent;
}

.sort-source-wrapper .nav > li.active > a:hover, .sort-source-wrapper .nav > li.active > a:focus {
	background: transparent;
}

/* Sort Source */
.sort-source {
	clear: both;
}

.sort-source > li > a {
	padding: 6px 12px;
}

.sort-source.sort-source-style-2 {
	margin: -10px auto;
}

.sort-source.sort-source-style-2 > li {
	float: none;
	display: inline-block;
}

.sort-source.sort-source-style-2 > li > a, .sort-source.sort-source-style-2 > li > a:focus, .sort-source.sort-source-style-2 > li > a:hover {
	background: transparent;
	color: #FFF;
	display: inline-block;
	border-radius: 0;
	margin: 0;
	padding: 18px 28px;
	position: relative;
}

.sort-source.sort-source-style-2 > li > a:after {
	-webkit-transition: margin 0.3s;
	-moz-transition: margin 0.3s;
	transition: margin 0.3s;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 15px solid #CCC;
	content: " ";
	display: block;
	position: absolute;
	left: 50%;
	margin-left: -20px;
	margin-top: 0;
	opacity: 0;
}

.sort-source.sort-source-style-2 > li.active > a, .sort-source.sort-source-style-2 > li.active > a:focus, .sort-source.sort-source-style-2 > li.active > a:hover {
	background: transparent;
}

.sort-source.sort-source-style-2 > li.active > a:after {
	opacity: 1;
	margin-top: 18px;
}

@media (max-width: 991px) {
	.sort-source.sort-source-style-2 > li {
		display: block;
	}

	.sort-source.sort-source-style-2 > li.active > a:after {
		display: none !important;
	}
}
/* Sort Destination */
.sort-destination {
	overflow: hidden;
}

.sort-destination.full-width {
	position: relative;
}

.sort-destination.full-width .isotope-item {
	width: 20%;
	float: left;
}

.sort-destination.full-width .isotope-item.w2 {
	width: 40%;
}

.sort-destination.full-width .isotope-item .img-thumbnail, .sort-destination.full-width .isotope-item a, .sort-destination.full-width .isotope-item .thumb-info img {
	border-radius: 0;
}

.sort-destination.full-width-spaced {
	margin-top: 50px;
	margin-left: 20px;
	margin-right: 20px;
}

.sort-destination.full-width-spaced .portfolio-item {
	margin-left: 20px;
	margin-right: 20px;
}

.sort-source-wrapper + .sort-destination.full-width {
	margin: 0 0 -81px;
	top: -51px;
}

.sort-source-wrapper + .sort-destination-loader .full-width {
	margin: 0 0 -81px;
	top: -51px;
}

/* Responsive */
@media (min-width: 992px) {
	.col-md-6.isotope-item:nth-child(2n+1) {
		clear: both;
	}

	.col-md-4.isotope-item:nth-child(3n+1) {
		clear: both;
	}

	.col-md-3.isotope-item:nth-child(4n+1) {
		clear: both;
	}

	.col-md-1-5.isotope-item:nth-child(5n+1) {
		clear: both;
	}

	.col-md-2.isotope-item:nth-child(6n+1) {
		clear: both;
	}

	.sort-destination.full-width .isotope-item:nth-child(5n+1) {
		clear: both;
	}
}

@media (max-width: 991px) {
	.isotope-item {
		max-width: 100%;
	}

	.sort-destination.isotope {
		overflow: visible !important;
		height: auto !important;
	}

	.sort-destination.isotope.full-width {
		overflow: hidden !important;
	}

	.isotope-hidden {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.isotope-item {
		position: static !important;
		text-align: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-o-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
	}

	.isotope-item.product {
		position: relative !important;
	}

	.sort-source-wrapper {
		background: #171717;
		clear: both;
		margin-top: -35px;
	}

	.sort-source-wrapper .nav {
		margin: 0;
	}

	.sort-destination {
		height: auto !important;
	}

	.sort-destination, .isotope-item {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.sort-destination.full-width,
	.sort-source-wrapper + .sort-destination.full-width {
		max-width: none;
		margin: 0 0 -30px;
		top: 0;
	}

	.sort-destination.full-width .isotope-item,
	.sort-source-wrapper + .sort-destination.full-width .isotope-item {
		max-width: none;
	}
}

@media (max-width: 479px) {
	.sort-destination, .isotope-item {
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.sort-destination.full-width .isotope-item,
	.sort-source-wrapper + .sort-destination.full-width .isotope-item {
		position: static !important;
		clear: both;
		float: none;
		width: auto;
	}
}
/* Sort Loading */
.sort-destination-loader {
	height: auto;
	overflow: hidden;
	position: relative;
}

.sort-destination-loader:after {
	-webkit-transition: opacity 0.3s;
	-moz-transition: opacity 0.3s;
	transition: opacity 0.3s;
	content: ' ';
	background: #FFF;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	opacity: 0;
}

.sort-destination-loader .bounce-loader {
	display: none;
}

.sort-destination-loader.sort-destination-loader-showing {
	max-height: 200px;
}

.sort-destination-loader.sort-destination-loader-showing:after {
	opacity: 1;
}

.sort-destination-loader.sort-destination-loader-showing .bounce-loader {
	display: block;
}

.sort-destination-loader.sort-destination-loader-loaded {
	overflow: visible;
}

.sort-destination-loader.sort-destination-loader-loaded:after {
	display: none;
}

.sort-destination-loader-full-width {
	overflow: visible;
}

.sort-destination-loader-full-width:after {
	bottom: -81px;
	top: -51px;
}

.sort-destination-loader-full-width.sort-destination-loader-showing {
	overflow: hidden;
	height: 600px;
}

.sort-destination-loader-lg.sort-destination-loader-showing {
	height: 600px;
}

/* Pricing Tables */
.pricing-table {
	margin: 10px 0;
	padding: 0 15px;
	text-align: center;
}

.pricing-table ul {
	list-style: none;
	margin: 20px 0 0 0;
	padding: 0;
}

.pricing-table li {
	border-top: 1px solid #ddd;
	padding: 10px 0;
	color: #777;
}

.pricing-table h3 {
	background-color: #eee;
	border-radius: 2px 2px 0 0;
	font-size: 1.3em;
	font-weight: normal;
	margin: -20px -20px 50px -20px;
	padding: 20px;
}

.pricing-table h3 .desc {
	font-size: 0.7em;
	margin-bottom: 0;
	padding-bottom: 0;
	color: #777;
	font-style: normal;
	text-transform: none;
	display: block;
}

.pricing-table h3 span {
	background: #FFF;
	border: 5px solid #FFF;
	border-radius: 100px;
	box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
	color: #777;
	display: block;
	font: bold 25px / 100px Georgia, Serif;
	height: 100px;
	margin: 20px auto -65px;
	width: 100px;
}

.pricing-table .most-popular {
	border: 3px solid #CCC;
	box-shadow: 11px 0 10px -10px rgba(0, 0, 0, 0.1), -11px 0 10px -10px rgba(0, 0, 0, 0.1);
	padding: 30px 20px;
	top: -10px;
	z-index: 2;
}

.pricing-table .most-popular h3 {
	background-color: #666;
	color: #FFF;
	padding-top: 30px;
	text-shadow: 0 1px #555;
}

.pricing-table .most-popular h3 .desc {
	color: #FFF;
}

.pricing-table .plan-ribbon-wrapper {
	height: 88px;
	overflow: hidden;
	position: absolute;
	right: -3px;
	top: -5px;
	width: 85px;
}

.pricing-table .plan-ribbon {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background-image: -webkit-linear-gradient(top, #bfdc7a, #8ebf45);
	background-image: linear-gradient(to bottom, #bfdc7a, #8ebf45);
	background-color: #bfdc7a;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	color: #333;
	font-size: 14px;
	left: -5px;
	padding: 7px 0;
	position: relative;
	text-align: center;
	text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0;
	top: 15px;
	width: 120px;
}

.pricing-table .plan-ribbon:before {
	left: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	bottom: -3px;
	content: "";
	position: absolute;
}

.pricing-table .plan-ribbon:after {
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	bottom: -3px;
	content: "";
	position: absolute;
	right: 0;
}

.pricing-table [class*="col-md-"] {
	padding-left: 0;
	padding-right: 0;
}

.pricing-table .plan {
	background: #FFF;
	border: 1px solid #ddd;
	border-radius: 5px;
	color: #333;
	margin-bottom: 35px;
	margin-right: 0;
	padding: 20px;
	position: relative;
	text-shadow: 0 1px rgba(255, 255, 255, 0.8);
}

.pricing-table .btn {
	margin-top: 5px;
}

.pricing-table.pricing-table-sm h3 .desc {
	font-size: 0.7em;
}

.pricing-table.pricing-table-sm h3 span {
	border: 3px solid #FFF;
	box-shadow: 0 5px 20px #ddd inset, 0 3px 0 #999 inset;
	font: bold 19px/65px Georgia,serif;
	height: 70px;
	margin: 10px auto -65px;
	width: 70px;
}

.pricing-table.pricing-table-sm .most-popular {
	padding-bottom: 30px;
}

.pricing-table.princig-table-flat .plan {
	padding-left: 0;
	padding-right: 0;
	margin: -1px;
}

.pricing-table.princig-table-flat .plan h3 {
	padding-left: 0;
	padding-right: 0;
	margin: -20px 0 0;
	background-color: #666;
	color: #FFF;
	padding-top: 30px;
	text-shadow: 0 1px #555;
}

.pricing-table.princig-table-flat .plan h3 .desc {
	color: #FFF;
}

.pricing-table.princig-table-flat .plan h3 span {
	border-radius: 0;
	border: 0;
	background: #666;
	color: #FFF;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 600;
	box-shadow: none;
	width: 100%;
	font-size: 1.5em;
	height: auto;
	line-height: 38px;
	margin: 0;
}

.pricing-table.princig-table-flat .plan ul {
	margin-top: 0;
	margin-bottom: -15px;
}

.pricing-table.princig-table-flat .plan ul li {
	padding: 8px 0;
}

.pricing-table.princig-table-flat .plan.most-popular ul li {
	padding: 10px 0;
}

.pricing-table.spaced [class*="col-md-"] {
	padding-left: 2px;
	padding-right: 2px;
}

.pricing-table.no-borders .plan {
	border: 0 !important;
}

/* Pricing Tables Boxed */
html.boxed .pricing-table .plan, html.boxed .pricing-table h3 {
	border-radius: 0;
}

/* Responsive */
@media (max-width: 767px) {
	/* Pricing Tables */
	.pricing-table {
		margin-left: 0;
	}

	.pricing-table .plan {
		margin-right: 0;
	}

	html.boxed .pricing-table {
		margin-left: 0;
	}
}
/* Icon Featured */
.icon-featured {
	display: inline-block;
	font-size: 0;
	margin: 15px;
	width: 110px;
	height: 110px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	z-index: 1;
	color: #FFF;
	font-size: 40px;
	line-height: 110px;
}

.icon-featured:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

/* Featured Box */
.featured-box {
	background: #F5F5F5;
	background: -webkit-linear-gradient( top , #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	background: linear-gradient(to bottom, #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-radius: 8px;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	min-height: 100px;
	position: relative;
	text-align: center;
	z-index: 1;
}

.featured-box h4 {
	font-size: 1.3em;
	font-weight: 400;
	letter-spacing: -0.7px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.featured-box .box-content {
	border-radius: 8px;
	border-top: 1px solid #CCC;
	border-top-width: 4px;
	padding: 30px 30px 10px 30px;
	position: relative;
	top: -1px;
}

.featured-box .learn-more {
	display: block;
	margin-top: 10px;
}

/* Featured Box Left */
.featured-box-text-left {
	text-align: left;
}

/* Featured Boxes - Flat */
.featured-boxes-flat .featured-box {
	box-shadow: none;
	margin: 10px 0;
}

.featured-boxes-flat .featured-box .box-content {
	background: #FFF;
	margin-top: 65px;
}

.featured-boxes-flat .featured-box .icon-featured {
	display: inline-block;
	font-size: 38px;
	height: 90px;
	line-height: 90px;
	padding: 0;
	width: 90px;
	margin: -100px 0 -15px;
	position: relative;
	top: -37px;
}

/* Featured Boxes - Style 2 */
.featured-boxes-style-2 .featured-box {
	background: none;
	border: 0;
	margin: 10px 0;
	box-shadow: none;
}

.featured-boxes-style-2 .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.featured-boxes-style-2 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	line-height: 75px;
	padding: 0;
	width: 75px;
	margin-top: 0;
}

/* Featured Boxes - Style 3 */
.featured-boxes-style-3 .featured-box {
	margin: 10px 0;
}

.featured-boxes-style-3 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	line-height: 75px;
	padding: 0;
	width: 75px;
	margin-top: -35px;
	background: #FFF;
	border: 3px solid #CCC;
	color: #CCC;
	line-height: 68px;
}

.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box {
	background: none;
	border: 0;
	box-shadow: none;
}

.featured-boxes-style-3:not(.featured-boxes-flat) .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

/* Featured Boxes - Style 4 */
.featured-boxes-style-4 .featured-box {
	background: none;
	border: 0;
	margin: 10px 0;
	box-shadow: none;
}

.featured-boxes-style-4 .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.featured-boxes-style-4 .featured-box .icon-featured {
	display: inline-block;
	font-size: 40px;
	height: 45px;
	line-height: 45px;
	padding: 0;
	width: 45px;
	margin-top: 0;
	margin-bottom: 10px;
	background: transparent;
	color: #CCC;
	border-radius: 0;
}

/* Featured Boxes - Style 5, 6 and 7 */
.featured-boxes-style-5 .featured-box, .featured-boxes-style-6 .featured-box, .featured-boxes-style-7 .featured-box {
	background: none;
	border: 0;
	box-shadow: none;
	margin: 10px 0;
}

.featured-boxes-style-5 .featured-box .box-content, .featured-boxes-style-6 .featured-box .box-content, .featured-boxes-style-7 .featured-box .box-content {
	border: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.featured-boxes-style-5 .featured-box .box-content h4, .featured-boxes-style-6 .featured-box .box-content h4, .featured-boxes-style-7 .featured-box .box-content h4 {
	color: #2e353e;
}

.featured-boxes-style-5 .featured-box .icon-featured, .featured-boxes-style-6 .featured-box .icon-featured, .featured-boxes-style-7 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	padding: 0;
	width: 75px;
	margin-top: 0;
	margin-bottom: 10px;
	background: #FFF;
	border: 1px solid #dfdfdf;
	color: #777;
	line-height: 73px;
}

/* Featured Boxes - Style 6 */
.featured-boxes-style-6 .featured-box .icon-featured {
	border: 1px solid #cecece;
	color: #777;
}

.featured-boxes-style-6 .featured-box .icon-featured:after {
	border: 5px solid #f4f4f4;
	border-radius: 50%;
	box-sizing: content-box;
	content: "";
	display: block;
	height: 100%;
	left: -6px;
	padding: 1px;
	position: absolute;
	top: -6px;
	width: 100%;
}

/* Featured Boxes - Style 7 */
.featured-boxes-style-7 .featured-box .icon-featured {
	border: 1px solid #dfdfdf;
	color: #777;
}

/* Featured Boxes - Style 8 */
.featured-boxes-style-8 .featured-box .icon-featured {
	display: inline-block;
	font-size: 30px;
	height: 75px;
	padding: 0;
	width: 75px;
	margin: -15px -15px 0 0;
	background: #FFF;
	line-height: 73px;
	border: 0;
	color: #777;
}

.featured-boxes-style-8 .featured-box .icon-featured:after {
	display: none;
}

/* Featured Boxes */
.featured-boxes .featured-box {
	margin-bottom: 30px;
	margin-top: 45px;
}

/* Effects */
.featured-box-effect-1 .icon-featured:after {
	top: -7px;
	left: -7px;
	padding: 7px;
	box-shadow: 0 0 0 3px #FFF;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-webkit-transform: scale(1);
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transition: transform 0.2s, opacity 0.2s;
	transform: scale(1);
	opacity: 0;
}

.featured-box-effect-1:hover .icon-featured:after {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 1;
}

.featured-box-effect-2 .icon-featured:after {
	top: -7px;
	left: -7px;
	padding: 7px;
	box-shadow: 0 0 0 3px #FFF;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-webkit-transform: scale(0.8);
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transition: transform 0.2s, opacity 0.2s;
	transform: scale(0.8);
	opacity: 0;
}

.featured-box-effect-2:hover .icon-featured:after {
	-webkit-transform: scale(0.95);
	-moz-transform: scale(0.95);
	-ms-transform: scale(0.95);
	transform: scale(0.95);
	opacity: 1;
}

.featured-box-effect-3 .icon-featured:after {
	top: 0;
	left: 0;
	box-shadow: 0 0 0 10px #FFF;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-webkit-transform: scale(0.9);
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	-moz-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transition: transform 0.2s, opacity 0.2s;
	transform: scale(0.9);
	opacity: 0;
}

.featured-box-effect-3:hover .icon-featured {
	color: #FFF !important;
}

.featured-box-effect-3:hover .icon-featured:after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 0.8;
}

.featured-box-effect-4 .icon-featured {
	-webkit-transition: -webkit-transform 0.2s, transform 0.2s;
	-moz-transition: -moz-transform 0.2s, transform 0.2s;
	transition: transform 0.2s, transform 0.2s;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.featured-box-effect-4:hover .icon-featured {
	-webkit-transform: scale(1.15);
	-moz-transform: scale(1.15);
	-ms-transform: scale(1.15);
	transform: scale(1.15);
}

.featured-box-effect-5 .icon-featured {
	overflow: hidden;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.featured-box-effect-5:hover .icon-featured {
	-webkit-animation: toRightFromLeft 0.3s forwards;
	-moz-animation: toRightFromLeft 0.3s forwards;
	animation: toRightFromLeft 0.3s forwards;
}

@-webkit-keyframes toRightFromLeft {
	49% {
		-webkit-transform: translate(100%);
	}

	50% {
		opacity: 0;
		-webkit-transform: translate(-100%);
	}

	51% {
		opacity: 1;
	}
}

@-moz-keyframes toRightFromLeft {
	49% {
		-moz-transform: translate(100%);
	}

	50% {
		opacity: 0;
		-moz-transform: translate(-100%);
	}

	51% {
		opacity: 1;
	}
}

@keyframes toRightFromLeft {
	49% {
		transform: translate(100%);
	}

	50% {
		opacity: 0;
		transform: translate(-100%);
	}

	51% {
		opacity: 1;
	}
}

.featured-box-effect-6 .icon-featured:after {
	-webkit-transition: all 0.2s, -webkit-transform 0.2s;
	-moz-transition: all 0.2s, -moz-transform 0.2s;
	transition: all 0.2s, transform 0.2s;
}

.featured-box-effect-6:hover .box-content .icon-featured:after {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.featured-box-effect-7 .icon-featured:after {
	opacity: 0;
	box-shadow: 3px 3px #d5d5d5;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
	-moz-transition: opacity 0.2s, -moz-transform 0.2s;
	transition: opacity 0.2s, transform 0.2s;
	left: 0;
	top: -1px;
}

.featured-box-effect-7:hover .icon-featured:after {
	opacity: 1;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.featured-box-effect-7 .icon-featured:before {
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0.7;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	-moz-transition: -moz-transform 0.2s, opacity 0.2s;
	transition: transform 0.2s, opacity 0.2s;
}

.featured-box-effect-7:hover .icon-featured:before {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Feature Box */
.feature-box {
	clear: both;
}

.feature-box .feature-box-icon {
	background: #CCC;
	border-radius: 35px;
	color: #FFF;
	display: inline-block;
	float: left;
	height: 35px;
	line-height: 35px;
	margin-right: 10px;
	position: relative;
	text-align: center;
	top: 5px;
	width: 35px;
}

.feature-box .feature-box-info {
	padding-left: 50px;
}

/* Style 2 */
.feature-box.feature-box-style-2 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-2 .feature-box-icon {
	background: transparent;
	width: 50px;
	height: 50px;
}

.feature-box.feature-box-style-2 .feature-box-icon i.fa,
.feature-box.feature-box-style-2 .feature-box-icon i.icons {
	font-size: 28px;
}

.feature-box.feature-box-style-2 .feature-box-info {
	padding-left: 60px;
}

/* Style 3 */
.feature-box.feature-box-style-3 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-3 .feature-box-icon {
	border: 1px solid #CCC;
	background: transparent;
	line-height: 32px;
}

.feature-box.feature-box-style-3 .feature-box-icon i.fa,
.feature-box.feature-box-style-3 .feature-box-icon i.icons {
	color: #CCC;
}

/* Style 4 */
.feature-box.feature-box-style-4 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-4 .feature-box-icon {
	background: transparent;
	padding-bottom: 10px;
	height: auto;
	width: auto;
	float: none;
}

.feature-box.feature-box-style-4 .feature-box-icon i.fa,
.feature-box.feature-box-style-4 .feature-box-icon i.icons {
	font-size: 38px;
}

.feature-box.feature-box-style-4 .feature-box-info {
	clear: both;
	padding-left: 0;
}

/* Style 5 */
.feature-box.feature-box-style-5 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-5 .feature-box-icon {
	background: transparent;
	width: 74px;
	height: 74px;
}

.feature-box.feature-box-style-5 .feature-box-icon i.fa,
.feature-box.feature-box-style-5 .feature-box-icon i.icons {
	font-size: 44px;
}

.feature-box.feature-box-style-5 .feature-box-info {
	padding-left: 84px;
}

/* Style 6 */
.feature-box.feature-box-style-6 h4 {
	color: #1d2127;
}

.feature-box.feature-box-style-6 .feature-box-icon {
	border: 1px solid #cecece;
	background: transparent;
	line-height: 32px;
}

.feature-box.feature-box-style-6 .feature-box-icon:after {
	border: 3px solid #f4f4f4;
	border-radius: 50%;
	box-sizing: content-box;
	content: "";
	display: block;
	height: 100%;
	left: -4px;
	padding: 1px;
	position: absolute;
	top: -4px;
	width: 100%;
}

.feature-box.feature-box-style-6 .feature-box-icon i.fa,
.feature-box.feature-box-style-6 .feature-box-icon i.icons {
	color: #a9a9a9;
}

/* Reverse */
@media (min-width: 992px) {
	.feature-box.reverse {
		text-align: right;
	}

	.feature-box.reverse .feature-box-info {
		padding-right: 50px;
		padding-left: 0;
	}

	.feature-box.reverse .feature-box-icon {
		float: right;
		margin-right: 0;
		margin-left: 10px;
	}

	.feature-box.reverse.feature-box-style-2 .feature-box-info {
		padding-right: 60px;
	}

	.feature-box.reverse.feature-box-style-4 .feature-box-info {
		padding-right: 0;
	}

	.feature-box.reverse.feature-box-style-5 .feature-box-info {
		padding-right: 84px;
	}
}
/* Full Width */
.featured-boxes-full {
	width: 100%;
}

.featured-boxes-full > [class*="col-"] {
	padding: 0;
}

.featured-boxes-full .featured-box-full {
	text-align: center;
	padding: 55px;
}

.featured-boxes-full .featured-box-full .fa, .featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full h1, .featured-boxes-full .featured-box-full h2, .featured-boxes-full .featured-box-full h3, .featured-boxes-full .featured-box-full h4, .featured-boxes-full .featured-box-full h5, .featured-boxes-full .featured-box-full h6, .featured-boxes-full .featured-box-full p, .featured-boxes-full .featured-box-full a {
	color: #FFF;
	padding: 0;
	margin: 0;
}

.featured-boxes-full .featured-box-full .icons, .featured-boxes-full .featured-box-full .fa {
	font-size: 55px;
	margin-bottom: 15px;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.featured-boxes-full .featured-box-full p {
	padding-top: 12px;
	opacity: 0.8;
}

/* Thumb Info */
.thumb-info {
	display: block;
	position: relative;
	text-decoration: none;
	max-width: 100%;
	background-color: #FFF;
	border: 1px solid #DDD;
	border-radius: 4px;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.thumb-info .thumb-info-wrapper {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	border-radius: 4px;
	margin: 4px;
	overflow: hidden;
	display: block;
	position: relative;
}

.thumb-info .thumb-info-wrapper:after {
	content: "";
	background: rgba(23, 23, 23, 0.8);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 4px;
	bottom: -4px;
	color: #FFF;
	left: -4px;
	position: absolute;
	right: -4px;
	top: -4px;
	display: block;
	opacity: 0;
	z-index: 1;
}

.thumb-info .thumb-info-action-icon {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: #CCC;
	border-radius: 0 0 0 25px;
	display: inline-block;
	font-size: 25px;
	height: 50px;
	line-height: 50px;
	position: absolute;
	right: -100px;
	text-align: center;
	top: -100px;
	width: 50px;
	z-index: 2;
}

.thumb-info .thumb-info-action-icon i {
	font-size: 24px;
	left: 3px;
	position: relative;
	top: -4px;
	color: #FFF;
}

.thumb-info .thumb-info-inner {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	display: block;
}

.thumb-info .thumb-info-inner em {
	display: block;
	font-size: 0.8em;
	font-style: normal;
	font-weight: normal;
}

.thumb-info .thumb-info-title {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	background: rgba(23, 23, 23, 0.8);
	bottom: 10%;
	color: #FFF;
	font-size: 18px;
	font-weight: 700;
	left: 0;
	letter-spacing: -1px;
	padding: 9px 11px 9px;
	position: absolute;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	text-transform: uppercase;
	z-index: 2;
	max-width: 90%;
}

.thumb-info .thumb-info-type {
	background-color: #CCC;
	border-radius: 2px;
	display: inline-block;
	float: left;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0;
	margin: 8px -2px -15px -2px;
	padding: 2px 9px;
	text-transform: none;
	z-index: 2;
}

.thumb-info .owl-carousel {
	z-index: auto;
}

.thumb-info .owl-carousel .owl-dots {
	z-index: 2;
}

.thumb-info img {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	border-radius: 3px;
	position: relative;
	width: 100%;
}

.thumb-info:hover .thumb-info-wrapper:after {
	opacity: 1;
}

.thumb-info:hover .thumb-info-action-icon {
	right: 0;
	top: 0;
}

.thumb-info:hover .thumb-info-title {
	background: #000;
}

.thumb-info:hover img {
	-webkit-transform: scale(1.1, 1.1);
	-moz-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	-o-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}

.thumb-info.thumb-info-no-zoom img {
	-webkit-transition: initial;
	-moz-transition: initial;
	transition: initial;
}

.thumb-info.thumb-info-no-zoom:hover img {
	-webkit-transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
}

.thumb-info.thumb-info-lighten .thumb-info-wrapper:after {
	background: rgba(23, 23, 23, 0.25);
}

.thumb-info.thumb-info-hide-wrapper-bg .thumb-info-wrapper:after {
	display: none;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action {
	-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	transform: translate3d(0, -10px, 0);
	top: 50%;
	left: 3px;
	right: auto;
	bottom: auto;
	width: 100%;
	height: 40px;
	margin-top: -20px;
	text-align: center;
	position: absolute;
	opacity: 0;
	z-index: 2;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon {
	-webkit-transition: all 0.1s ease;
	-moz-transition: all 0.1s ease;
	transition: all 0.1s ease;
	position: relative;
	z-index: 2;
	left: auto;
	right: auto;
	top: auto;
	bottom: auto;
	width: 40px;
	height: 40px;
	line-height: 33px;
	border-radius: 50%;
	margin-right: 3px;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon i {
	position: static;
	font-size: 18px;
}

.thumb-info.thumb-info-centered-icons .thumb-info-action-icon:hover {
	-webkit-transform: scale(1.15, 1.15);
	-moz-transform: scale(1.15, 1.15);
	-ms-transform: scale(1.15, 1.15);
	-o-transform: scale(1.15, 1.15);
	transform: scale(1.15, 1.15);
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.thumb-info:hover.thumb-info-centered-icons .thumb-info-action-icon {
	position: relative;
}

.thumb-info.thumb-info-centered-info .thumb-info-title {
	background: transparent;
	left: 5%;
	width: 90%;
	-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	transform: translate(0%, -30%);
	top: 50%;
	bottom: auto;
	opacity: 0;
	text-align: center;
	padding: 0;
	position: absolute;
}

.thumb-info.thumb-info-centered-info .thumb-info-type {
	float: none;
}

.thumb-info.thumb-info-centered-info:hover .thumb-info-title {
	opacity: 1;
	transform: translate(0%, -50%);
}

.thumb-info.thumb-info-bottom-info .thumb-info-title {
	background: #FFF;
	left: 0;
	width: 100%;
	max-width: 100%;
	-webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.2s ease-out;
	-moz-transition: -moz-transform 0.2s ease-out, opacity 0.2s ease-out;
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	transform: translate3d(0, 100px, 0);
	top: auto;
	bottom: 0;
	opacity: 0;
	padding: 15px;
	text-shadow: none;
	color: #777;
}

.thumb-info.thumb-info-bottom-info .thumb-info-type {
	float: none;
	background: none;
	padding: 0;
	margin: 0;
}

.thumb-info.thumb-info-bottom-info:hover .thumb-info-title {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.thumb-info.thumb-info-bottom-info:hover img {
	-webkit-transform: translateY(-18px);
	-moz-transform: translateY(-18px);
	-ms-transform: translateY(-18px);
	-o-transform: translateY(-18px);
	transform: translateY(-18px);
}

.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
	background: -webkit-linear-gradient( bottom , rgba(23, 23, 23, 0.8) 0, transparent 100%) repeat scroll 0 0 transparent;
	background: linear-gradient(to top, rgba(23, 23, 23, 0.8) 0, transparent 100%) repeat scroll 0 0 transparent;
	color: #FFF;
}

.thumb-info.thumb-info-push-hor img {
	-webkit-transform: translatex(-18px);
	-moz-transform: translatex(-18px);
	-ms-transform: translatex(-18px);
	-o-transform: translatex(-18px);
	transform: translatex(-18px);
	width: calc(100% + 19px);
	max-width: none;
}

.thumb-info.thumb-info-push-hor:hover img {
	-webkit-transform: translatex(0);
	-moz-transform: translatex(0);
	-ms-transform: translatex(0);
	-o-transform: translatex(0);
	transform: translatex(0);
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-wrapper:after {
	opacity: 0.65;
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-title {
	opacity: 1;
	top: 50%;
}

.thumb-info.thumb-info-hide-info-hover .thumb-info-type {
	float: none;
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-wrapper:after {
	opacity: 0.1;
}

.thumb-info.thumb-info-hide-info-hover:hover .thumb-info-title {
	opacity: 0;
}

.thumb-info.thumb-info-no-borders, .thumb-info.thumb-info-no-borders img {
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 0;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper {
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 0;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper:after {
	border-radius: 0;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

.thumb-info.thumb-info-preview .thumb-info-wrapper:after {
	display: none;
}

.thumb-info.thumb-info-preview .thumb-info-image {
	min-height: 232px;
	display: block;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: 100% auto;
	position: relative;
	transition: background-position 0.8s linear 0s;
}

.thumb-info.thumb-info-preview:hover .thumb-info-image {
	transition: background-position 2.5s linear 0s;
	background-position: center bottom;
}

.thumb-info.thumb-info-preview.thumb-info-preview-short:hover .thumb-info-image {
	transition: background-position 1s linear 0s;
}

.thumb-info.thumb-info-preview.thumb-info-preview-long:hover .thumb-info-image {
	transition: background-position 5s linear 0s;
}

html.webkit .thumb-info .thumb-info-wrapper {
	margin: 4px 4px 3px 4px;
}

html.webkit .full-width .thumb-info .thumb-info-wrapper,
html.webkit .masonry-item .thumb-info .thumb-info-wrapper,
html.webkit .thumb-info-no-borders .thumb-info-wrapper {
	margin: 0;
}

/* Thumb Info Ribbon */
.thumb-info-ribbon {
	background: #999;
	position: absolute;
	margin: -16px 0 0 0;
	padding: 5px 13px 6px;
	right: 15px;
	z-index: 1;
}

.thumb-info-ribbon:before {
	border-right: 10px solid #646464;
	border-top: 16px solid transparent;
	content: "";
	display: block;
	height: 0;
	left: -10px;
	position: absolute;
	top: 0;
	width: 7px;
}

.thumb-info-ribbon span {
	color: #FFF;
	font-size: 1.1em;
	font-family: "Shadows Into Light", cursive;
}

/* Thumb Info - Full Width */
.full-width .thumb-info img {
	border-radius: 0 !important;
}

/* Thumb Info Caption	*/
.thumb-info-caption {
	padding: 10px 0;
}

.thumb-info-caption .thumb-info-caption-text, .thumb-info-caption p {
	font-size: 0.9em;
	line-height: 20px;
	margin: 0 0 8px;
	padding: 10px;
	display: block;
}

/* Thumb Info Side Image	*/
.thumb-info-side-image .thumb-info-side-image-wrapper {
	padding: 4px;
	float: left;
	margin-right: 20px;
}

.thumb-info-side-image .thumb-info-caption .thumb-info-caption-text {
	padding: 17px;
}

.thumb-info-side-image-right .thumb-info-side-image-wrapper {
	float: right;
	margin-left: 20px;
	margin-right: 0;
}

/* Thumb Info Social Icons */
.thumb-info-social-icons {
	border-top: 1px dotted #DDD;
	margin: 0;
	padding: 15px 10px;
	display: block;
}

.thumb-info-social-icons a {
	background: #CCC;
	border-radius: 25px;
	display: inline-block;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}

.thumb-info-social-icons a:hover {
	text-decoration: none;
}

.thumb-info-social-icons a span {
	display: none;
}

.thumb-info-social-icons a i {
	color: #FFF;
	font-size: 0.9em;
	font-weight: normal;
}

/* Responsive */
@media (max-width: 991px) {
	.thumb-info .thumb-info-title {
		font-size: 14px;
	}

	.thumb-info .thumb-info-more {
		font-size: 11px;
	}
}
/* Timeline */
section.timeline {
	width: 75%;
	margin: 50px 12.5% 0;
	position: relative;
	float: left;
	padding-bottom: 120px;
}

section.timeline:after {
	background: #505050;
	background: -moz-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(100%, #7db9e8));
	background: -webkit-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -o-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: -ms-linear-gradient(top, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
	content: "";
	display: block;
	height: 100%;
	left: 50%;
	margin-left: -2px;
	position: absolute;
	top: -60px;
	width: 3px;
	z-index: 0;
	filter: alpha(opacity=35);
	opacity: 0.35;
}

section.timeline .timeline-date {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	position: relative;
	display: block;
	clear: both;
	width: 200px;
	height: 45px;
	padding: 5px;
	border: 1px solid #E5E5E5;
	margin: 0 auto 10px;
	text-shadow: 0 1px 1px #fff;
	border-radius: 4px;
	background: #FFF;
	z-index: 1;
}

section.timeline .timeline-date h3 {
	display: block;
	text-align: center;
	color: #757575;
	font-size: 0.9em;
	line-height: 32px;
}

section.timeline .timeline-title {
	background: #F4F4F4;
	padding: 12px;
}

section.timeline .timeline-title h4 {
	padding: 0;
	margin: 0;
	color: #171717;
	font-size: 1.4em;
}

section.timeline .timeline-title a {
	color: #171717;
}

section.timeline .timeline-title .timeline-title-tags {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-title .timeline-title-tags a {
	color: #B1B1B1;
	font-size: 0.9em;
}

section.timeline .timeline-box {
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
	border: 1px solid #E5E5E5;
	border-radius: 4px;
	margin: 20px 10px 10px 10px;
	padding: 10px;
	position: relative;
	width: 45.7%;
	float: left;
	z-index: 1;
	margin-bottom: 30px;
	background: #FFF;
}

section.timeline .timeline-box.left {
	clear: both;
}

section.timeline .timeline-box.left:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	margin-right: -5px;
	position: absolute;
	right: -6.8%;
	top: 22px;
	width: 8px;
}

section.timeline .timeline-box.left:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background: #FFF;
	border-right: 1px solid #E5E5E5;
	border-top: 1px solid #E5E5E5;
	content: "";
	display: block;
	height: 14px;
	position: absolute;
	right: -8px;
	top: 20px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box.right {
	clear: right;
	float: right;
	right: -1px;
	margin-top: 40px;
}

section.timeline .timeline-box.right:before {
	background: none repeat scroll 0 0 #E5E5E5;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #E5E5E5;
	content: "";
	display: block;
	height: 7px;
	margin-left: -5px;
	position: absolute;
	left: -7.2%;
	top: 32px;
	width: 8px;
}

section.timeline .timeline-box.right:after {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background: #FFF;
	border-left: 1px solid #E5E5E5;
	border-bottom: 1px solid #E5E5E5;
	content: "";
	display: block;
	height: 14px;
	position: absolute;
	left: -8px;
	top: 30px;
	width: 14px;
	z-index: 0;
}

section.timeline .timeline-box .owl-carousel {
	border-radius: 0;
	padding: 0;
	border: 0;
	box-shadow: none;
}

/* Responsive */
@media (max-width: 991px) {
	section.timeline .timeline-box {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.right {
		float: none;
		clear: both;
		right: auto;
		left: auto;
		margin: 25px auto;
		max-width: 70%;
		width: auto;
	}

	section.timeline .timeline-box.left:after, section.timeline .timeline-box.right:after, section.timeline .timeline-box.left:before, section.timeline .timeline-box.right:before {
		display: none;
	}
}

@media (max-width: 479px) {
	section.timeline {
		width: auto;
		float: none;
		margin-left: 0;
		margin-right: 0;
	}

	section.timeline .timeline-box {
		margin: 15px auto;
		max-width: 90%;
	}

	section.timeline .timeline-box.right {
		margin: 15px auto;
		max-width: 90%;
	}

	article.post-large {
		margin-left: 0;
	}

	article.post-large .post-image, article.post-large .post-date {
		margin-left: 0;
	}
}
/* Testimonials */
.testimonial {
	margin-bottom: 20px;
}

.testimonial blockquote {
	background: #CCC;
	border-radius: 10px;
	border: 0;
	color: #666;
	font-family: Georgia, serif;
	font-style: italic;
	margin: 0;
	padding: 10px 50px;
	position: relative;
}

.testimonial blockquote:before {
	left: 10px;
	top: 0;
	color: #FFF;
	content: "“";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
}

.testimonial blockquote:after {
	color: #FFF;
	content: "”";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
	bottom: -0.5em;
	right: 10px;
}

.testimonial blockquote p {
	color: #FFF;
	font-family: Georgia, serif;
	font-style: italic;
	font-size: 1.2em;
	line-height: 1.3;
}

.testimonial .testimonial-arrow-down {
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #CCC;
	height: 0;
	margin: 0 0 0 17px;
	width: 0;
}

.testimonial .testimonial-author {
	margin: 8px 0 0 0;
}

.testimonial .testimonial-author::after {
	clear: both;
	content: "";
	display: table;
}

.testimonial .testimonial-author .testimonial-author-thumbnail {
	float: left;
	margin-right: 10px;
	width: auto;
}

.testimonial .testimonial-author img {
	max-width: 55px;
}

.testimonial .testimonial-author strong {
	color: #111;
	display: block;
	padding-top: 10px;
	margin-bottom: -5px;
}

.testimonial .testimonial-author span {
	color: #666;
	display: block;
	font-size: 12px;
}

.testimonial .testimonial-author p {
	color: #999;
	margin: 0 0 0 25px;
	text-align: left;
}

/* Style 2 */
.testimonial.testimonial-style-2 {
	text-align: center;
}

.testimonial.testimonial-style-2 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 6px 20px;
}

.testimonial.testimonial-style-2 blockquote:before, .testimonial.testimonial-style-2 blockquote:after {
	display: none;
}

.testimonial.testimonial-style-2 blockquote p {
	color: #777;
}

.testimonial.testimonial-style-2 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-2 .testimonial-author {
	padding: 0;
	margin: 6px 0 0;
}

.testimonial.testimonial-style-2 .testimonial-author img {
	margin: 0 auto 10px;
}

.testimonial.testimonial-style-2 .testimonial-author p {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* Style 3 */
.testimonial.testimonial-style-3 blockquote {
	background: #f2f2f2 !important;
	border-radius: 4px;
	padding: 12px 30px;
}

.testimonial.testimonial-style-3 blockquote:before {
	top: 5px;
	color: #777;
	font-size: 35px;
}

.testimonial.testimonial-style-3 blockquote:after {
	color: #777;
	font-size: 35px;
	bottom: -5px;
}

.testimonial.testimonial-style-3 blockquote p {
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 1em;
	font-style: normal;
}

.testimonial.testimonial-style-3 .testimonial-author {
	margin-left: 8px;
}

.testimonial.testimonial-style-3 .testimonial-arrow-down {
	margin-left: 20px;
	border-top: 10px solid #f2f2f2 !important;
}

/* Style 4 */
.testimonial.testimonial-style-4 {
	border-radius: 6px;
	padding: 8px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.testimonial.testimonial-style-4 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 12px 30px;
}

.testimonial.testimonial-style-4 blockquote:before {
	top: 5px;
	color: #777;
	font-size: 35px;
}

.testimonial.testimonial-style-4 blockquote:after {
	color: #777;
	font-size: 35px;
	bottom: -5px;
}

.testimonial.testimonial-style-4 blockquote p {
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 1em;
	font-style: normal;
}

.testimonial.testimonial-style-4 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-4 .testimonial-author {
	margin-left: 30px;
	margin-bottom: 15px;
}

.testimonial.testimonial-style-4.testimonial-no-borders {
	border: 0;
	box-shadow: none;
}

/* Style 5 */
.testimonial.testimonial-style-5 {
	text-align: center;
}

.testimonial.testimonial-style-5 blockquote {
	background: transparent !important;
	border-radius: 4px;
	padding: 6px 20px;
}

.testimonial.testimonial-style-5 blockquote:before, .testimonial.testimonial-style-5 blockquote:after {
	display: none;
}

.testimonial.testimonial-style-5 blockquote p {
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 1.2em;
	font-style: normal;
}

.testimonial.testimonial-style-5 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-5 .testimonial-author {
	padding: 6px 0 0;
	margin: 12px 0 0;
	border-top: 1px solid #f2f2f2;
}

.testimonial.testimonial-style-5 .testimonial-author img {
	margin: 0 auto 10px;
}

.testimonial.testimonial-style-5 .testimonial-author p {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* Style 6 */
.testimonial.testimonial-style-6 {
	text-align: center;
}

.testimonial.testimonial-style-6 blockquote {
	background: transparent !important;
	border-radius: 4px;
	border: 0;
	padding: 0 10%;
}

.testimonial.testimonial-style-6 blockquote:before, .testimonial.testimonial-style-6 blockquote:after {
	display: none;
}

.testimonial.testimonial-style-6 blockquote i.fa-quote-left {
	font-size: 34px;
	position: relative;
	left: -5px;
}

.testimonial.testimonial-style-6 blockquote p {
	color: #777;
	font-size: 24px;
	line-height: 30px;
}

.testimonial.testimonial-style-6 blockquote span {
	font-size: 16px;
	line-height: 20px;
	color: #999;
	position: relative;
	left: -5px;
}

.testimonial.testimonial-style-6 .testimonial-arrow-down {
	display: none;
}

.testimonial.testimonial-style-6 .testimonial-author {
	padding: 0;
	margin: 6px 0 0;
}

.testimonial.testimonial-style-6 .testimonial-author img {
	margin: 0 auto 10px;
}

.testimonial.testimonial-style-6 .testimonial-author p {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* With Quotes */
.testimonial.testimonial-with-quotes blockquote:before {
	color: #777;
	display: block !important;
	left: 10px;
	top: 0;
	content: "“";
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
}

.testimonial.testimonial-with-quotes blockquote:after {
	color: #777;
	display: block !important;
	right: 10px;
	font-size: 80px;
	font-style: normal;
	line-height: 1;
	position: absolute;
	bottom: -0.5em;
	content: "”";
}

.testimonial.testimonial-with-quotes blockquote p {
	padding: 0 40px;
}

/* Transparent Background */
.testimonial.testimonial-trasnparent-background blockquote {
	background: transparent !important;
}

/* Alternarive Font */
.testimonial.testimonial-alternarive-font blockquote p {
	font-family: Georgia, serif;
	font-style: italic;
	font-size: 1.2em;
}

/* Light */
.section-text-light .testimonial.testimonial blockquote:before, .section-text-light .testimonial.testimonial blockquote:after, .section-text-light .testimonial.testimonial blockquote p {
	opacity: 0.9;
	color: #FFF;
}

.section-text-light .testimonial.testimonial .testimonial-author span, .section-text-light .testimonial.testimonial .testimonial-author strong {
	opacity: 0.6;
	color: #FFF;
}

/* Social Icons */
.social-icons {
	margin: 0;
	padding: 0;
	width: auto;
}

.social-icons li {
	display: inline-block;
	margin: -1px 1px 5px 0;
	padding: 0;
	border-radius: 100%;
	overflow: visible;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
}

.social-icons li a {
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
	background: #FFF;
	border-radius: 100%;
	display: block;
	height: 30px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	color: #333 !important;
	text-decoration: none;
}

.social-icons li:hover a {
	background: #171717;
	color: #FFF !important;
}

.social-icons li:hover.social-icons-twitter a {
	background: #1aa9e1;
}

.social-icons li:hover.social-icons-facebook a {
	background: #3b5a9a;
}

.social-icons li:hover.social-icons-linkedin a {
	background: #0073b2;
}

.social-icons li:hover.social-icons-rss a {
	background: #ff8201;
}

.social-icons li:hover.social-icons-googleplus a {
	background: #dd4b39;
}

.social-icons li:hover.social-icons-pinterest a {
	background: #cc2127;
}

.social-icons li:hover.social-icons-youtube a {
	background: #c3191e;
}

.social-icons li:hover.social-icons-instagram a {
	background: #7c4a3a;
}

.social-icons li:hover.social-icons-skype a {
	background: #00b0f6;
}

.social-icons li:hover.social-icons-email a {
	background: #dd4b39;
}

.social-icons li:hover.social-icons-vk a {
	background: #6383a8;
}

.social-icons li:hover.social-icons-xing a {
	background: #1a7576;
}

.social-icons li:hover.social-icons-tumblr a {
	background: #304e6c;
}

.social-icons li:hover.social-icons-reddit a {
	background: #ff4107;
}

.social-icons.social-icons-transparent li {
	box-shadow: none;
}

.social-icons.social-icons-transparent li a {
	background: transparent;
}

.social-icons.social-icons-icon-light li a i {
	color: #FFF;
}

/* Arrows */
.arrow {
	background: transparent url(../img/arrows.png) no-repeat 0 0;
	width: 47px;
	height: 120px;
	display: inline-block;
	position: relative;
}

.arrow.arrow-light {
	background-image: url(../img/arrows-dark.png);
}

.arrow.vtl {
	background-position: 0 0;
	width: 47px;
	height: 96px;
}

.arrow.vtr {
	background-position: -101px 0;
	width: 47px;
	height: 96px;
}

.arrow.vbl {
	background-position: 0 -144px;
	width: 47px;
	height: 96px;
}

.arrow.vbr {
	background-position: -101px -144px;
	width: 47px;
	height: 96px;
}

.arrow.hlt {
	background-position: -209px 0;
	width: 120px;
	height: 47px;
}

.arrow.hlb {
	background-position: -209px -101px;
	width: 120px;
	height: 47px;
}

.arrow.hrt {
	background-position: -353px 0;
	width: 120px;
	height: 47px;
}

.arrow.hrb {
	background-position: -353px -101px;
	width: 120px;
	height: 47px;
}

/* Responsive */
@media (max-width: 479px) {
	.arrow {
		display: none;
	}
}
/* Call to Action */
.call-to-action {
	position: relative;
	clear: both;
	min-height: 125px;
	padding-left: 25px;
	padding-right: 25px;
}

.call-to-action::after {
	clear: both;
	content: "";
	display: table;
}

.call-to-action h3 {
	text-transform: none;
	color: #1d2127;
	padding: 0;
	margin: 0 0 9px;
}

.call-to-action p {
	font-size: 1.2em;
}

.call-to-action .call-to-action-content {
	text-align: center;
	width: 70%;
	margin-left: 3%;
	padding-top: 35px;
}

.call-to-action .call-to-action-btn {
	width: 22%;
	text-align: center;
	position: absolute;
	top: 50%;
	margin: -18px 0 0;
	left: auto;
	right: 25px;
}

.call-to-action.call-to-action-front {
	z-index: 1;
}

.call-to-action.call-to-action-default {
	background: #F4F4F4;
}

.call-to-action.call-to-action-dark {
	background: #2e353e;
}

.call-to-action.call-to-action-dark h3, .call-to-action.call-to-action-dark p {
	color: #FFF;
}

.call-to-action.with-borders {
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.call-to-action.with-full-borders {
	border: 2px solid #CCC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.call-to-action.featured {
	background: -webkit-linear-gradient( top , #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	background: linear-gradient(to bottom, #FFF 1%, #fcfcfc 98%) repeat scroll 0 0 transparent;
	padding: 0;
	margin: 0;
	border-top: 3px solid #CCC;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

.call-to-action.featured.featured-primary {
	border-top-color: #CCC;
}

.call-to-action.featured.featured-secondary {
	border-top-color: #E36159;
}

.call-to-action.featured.featured-tertiary {
	border-top-color: #2BAAB1;
}

.call-to-action.featured.featured-quaternary {
	border-top-color: #2e353e;
}

.call-to-action.with-button-arrow .call-to-action-btn {
	top: 30%;
}

.call-to-action.button-centered .call-to-action-content {
	text-align: center;
	width: 100%;
	padding: 35px 0 0;
	margin: 0;
}

.call-to-action.button-centered .call-to-action-btn {
	text-align: center;
	position: static;
	margin: 20px auto 25px;
	width: auto;
}

.call-to-action.call-to-action-in-footer {
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top: 30px;
	margin-bottom: -50px !important;
}

.call-to-action.call-to-action-in-footer:before {
	content: "";
	display: block;
	position: absolute;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #FFF;
	height: 20px;
	width: 20px;
	top: -5px;
	left: 50%;
	margin-left: -20px;
}

.call-to-action.no-top-arrow:before {
	display: none;
}

.call-to-action.call-to-action-in-footer-margin-top {
	margin-top: 60px;
}

.container .call-to-action {
	border-radius: 8px;
}

/* Responsive */
@media (max-width: 767px) {
	.call-to-action .call-to-action-content,
	.call-to-action .call-to-action-btn {
		margin: 0;
		padding: 20px;
		position: static;
		width: auto;
	}
}
/* Circular Bars */
.circular-bar {
	text-align: center;
	margin-bottom: 25px;
}

.circular-bar .circular-bar-chart {
	position: relative;
}

.circular-bar strong {
	display: block;
	font-weight: 600;
	font-size: 18px;
	line-height: 30px;
	position: absolute;
	top: 35%;
	width: 80%;
	left: 10%;
	text-align: center;
}

.circular-bar label {
	display: block;
	font-weight: 100;
	font-size: 17px;
	line-height: 20px;
	position: absolute;
	top: 50%;
	width: 80%;
	left: 10%;
	text-align: center;
}

.circular-bar.only-icon .fa, .circular-bar.only-icon .icons {
	display: block;
	font-weight: 600;
	font-size: 38px;
	line-height: 30px;
	position: absolute;
	top: 40%;
	width: 80%;
	left: 10%;
	text-align: center;
}

.circular-bar.single-line strong {
	top: 40%;
}

.circular-bar.circular-bar-sm label {
	font-size: 14px;
	top: 36%;
}

.circular-bar.circular-bar-lg strong {
	font-size: 20px;
	top: 40%;
}

/* Progress */
.progress {
	border-radius: 25px;
	height: 20px;
	background: #FAFAFA;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
	overflow: visible;
}

.progress.progress-border-radius {
	border-radius: 3px;
}

.progress.progress-border-radius .progress-bar {
	border-radius: 3px;
}

.progress.progress-no-border-radius {
	border-radius: 0;
}

.progress.progress-no-border-radius .progress-bar {
	border-radius: 0;
}

.progress.progress-sm {
	height: 10px;
}

.progress.progress-sm .progress-bar {
	line-height: 10px;
}

.progress.progress-lg {
	height: 35px;
}

.progress.progress-lg .progress-bar {
	font-size: 13px;
	line-height: 35px;
}

.progress-bar {
	background-color: #CCC;
	box-shadow: none;
	position: relative;
	border-radius: 25px;
}

.progress-bar-tooltip {
	position: absolute;
	padding: 4px 8px;
	background-color: #333;
	color: #FFF;
	line-height: 15px;
	font-size: 11px;
	display: block;
	position: absolute;
	top: -28px;
	right: 5px;
	border-radius: 3px;
	opacity: 0;
}

.progress-bar-tooltip:after {
	border-color: #333 transparent;
	border-style: solid;
	border-width: 5px 5px 0;
	bottom: -5px;
	content: "";
	display: block;
	left: 13px;
	position: absolute;
	width: 0;
}

.progress-bar-primary {
	background-color: #CCC;
	color: #FFF;
}

.progress-bar-success {
	background-color: #47a447;
	color: #FFF;
}

.progress-bar-warning {
	background-color: #ed9c28;
	color: #FFF;
}

.progress-bar-danger {
	background-color: #d2322d;
	color: #FFF;
}

.progress-bar-info {
	background-color: #5bc0de;
	color: #FFF;
}

.progress-bar-dark {
	background-color: #1d2127;
	color: #FFF;
}

/* Counters */
.counters .counter {
	text-align: center;
}

.counters .fa, .counters .icons {
	display: block;
	font-size: 35px;
	line-height: 35px;
	margin-bottom: 5px;
}

.counters strong {
	display: block;
	font-weight: bold;
	font-size: 50px;
	line-height: 50px;
}

.counters label {
	display: block;
	font-weight: 100;
	font-size: 20px;
	line-height: 20px;
}

.counters .counter-side-icon .fa, .counters .counter-side-icon .icons, .counters .counter-side-icon strong {
	display: inline-block;
}

.counters .counter-with-unit strong {
	display: inline-block;
}

.counters.with-borders .counter {
	border-radius: 6px;
	border-top: 1px solid #DFDFDF;
	border-bottom: 1px solid #DFDFDF;
	border-left: 1px solid #ECECEC;
	border-right: 1px solid #ECECEC;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
	padding: 25px;
}

.counters.counters-sm .fa, .counters.counters-sm .icons {
	font-size: 25px;
	line-height: 25px;
}

.counters.counters-sm strong {
	font-size: 35px;
	line-height: 35px;
}

.counters.counters-sm label {
	font-size: 15px;
	line-height: 15px;
}

.counters.counters-lg .fa {
	font-size: 45px;
	line-height: 45px;
}

.counters.counters-lg strong {
	font-size: 60px;
	line-height: 60px;
}

.counters.counters-lg label {
	font-size: 25px;
	line-height: 25px;
}

.counters.counters-text-light .counter {
	color: #FFF !important;
}

.counters.counters-text-light .counter .fa, .counters.counters-text-light .counter .icons, .counters.counters-text-light .counter strong, .counters.counters-text-light .counter label {
	color: #FFF !important;
}

.counters.counters-text-dark .counter {
	color: #2e353e !important;
}

.counters.counters-text-dark .counter .fa, .counters.counters-text-dark .counter .icons, .counters.counters-text-dark .counter strong, .counters.counters-text-dark .counter label {
	color: #2e353e !important;
}

/* Word Rotate */
.word-rotate {
	display: inline-block;
	overflow: hidden;
	text-align: center;
	position: relative;
	max-height: 22px;
	line-height: 22px;
}

.word-rotate .word-rotate-items {
	position: relative;
	top: 0;
	width: 0;
	display: inline-block;
}

.word-rotate .word-rotate-items span {
	display: inline-block;
	white-space: nowrap;
}

.word-rotate .word-rotate-items span:not(:first-child) {
	display: none;
}

.word-rotate.active .word-rotate-items {
	position: relative;
}

.word-rotate.active .word-rotate-items span {
	display: inline-block;
	float: left;
	clear: both;
}

h1.word-rotator-title .inverted {
	min-height: 56px;
}

h1.word-rotator-title .word-rotate {
	max-height: 46px;
	line-height: 46px;
	margin-bottom: -9px;
}

h2.word-rotator-title .inverted {
	min-height: 46px;
}

h2.word-rotator-title .word-rotate {
	max-height: 42px;
	line-height: 42px;
	margin-bottom: -9px;
}

h3.word-rotator-title .inverted {
	min-height: 25px;
}

h3.word-rotator-title .word-rotate {
	max-height: 24px;
	line-height: 24px;
	margin-bottom: -2px;
}

h4.word-rotator-title .inverted {
	min-height: 32px;
}

h4.word-rotator-title .word-rotate {
	max-height: 27px;
	line-height: 27px;
	margin-bottom: -6px;
}

h5.word-rotator-title .inverted {
	min-height: 18px;
}

h5.word-rotator-title .word-rotate {
	max-height: 18px;
	line-height: 18px;
	margin-bottom: -4px;
}

h6.word-rotator-title .inverted {
	min-height: 20px;
}

h6.word-rotator-title .word-rotate {
	max-height: 18px;
	line-height: 18px;
	margin-bottom: -4px;
}

p .inverted {
	min-height: 24px;
}

p .word-rotate {
	max-height: 22px;
	line-height: 22px;
	margin-bottom: -6px;
}

/* Scroll to Top */
html .scroll-to-top {
	-webkit-transition: opacity 0.3s;
	-moz-transition: opacity 0.3s;
	transition: opacity 0.3s;
	background: #404040;
	border-radius: 7px 7px 0 0;
	bottom: 0;
	color: #FFF;
	display: block;
	height: 9px;
	opacity: 0;
	padding: 13px 10px 35px;
	position: fixed;
	right: 10px;
	text-align: center;
	text-decoration: none;
	min-width: 49px;
	z-index: 1040;
}

html .scroll-to-top:hover {
	opacity: 1;
}

html .scroll-to-top.visible {
	opacity: 0.75;
}

html .scroll-to-top span {
	display: inline-block;
	padding: 0 5px;
}

html.lightbox-opened .scroll-to-top {
	right: 27px;
}

html.ie11 .scroll-to-top {
	right: 25px;
}

/* Responsive */
@media (max-width: 991px) {
	html .scroll-to-top.hidden-mobile {
		display: none !important;
	}
}

.flag {
	width: 16px;
	height: 11px;
	background: url(../img/flags.png) no-repeat;
}

.flag.flag-ad {
	background-position: -16px 0;
}

.flag.flag-ae {
	background-position: -32px 0;
}

.flag.flag-af {
	background-position: -48px 0;
}

.flag.flag-ag {
	background-position: -64px 0;
}

.flag.flag-ai {
	background-position: -80px 0;
}

.flag.flag-al {
	background-position: -96px 0;
}

.flag.flag-am {
	background-position: -112px 0;
}

.flag.flag-an {
	background-position: -128px 0;
}

.flag.flag-ao {
	background-position: -144px 0;
}

.flag.flag-ar {
	background-position: -160px 0;
}

.flag.flag-as {
	background-position: -176px 0;
}

.flag.flag-at {
	background-position: -192px 0;
}

.flag.flag-au {
	background-position: -208px 0;
}

.flag.flag-aw {
	background-position: -224px 0;
}

.flag.flag-az {
	background-position: -240px 0;
}

.flag.flag-ba {
	background-position: 0 -11px;
}

.flag.flag-bb {
	background-position: -16px -11px;
}

.flag.flag-bd {
	background-position: -32px -11px;
}

.flag.flag-be {
	background-position: -48px -11px;
}

.flag.flag-bf {
	background-position: -64px -11px;
}

.flag.flag-bg {
	background-position: -80px -11px;
}

.flag.flag-bh {
	background-position: -96px -11px;
}

.flag.flag-bi {
	background-position: -112px -11px;
}

.flag.flag-bj {
	background-position: -128px -11px;
}

.flag.flag-bm {
	background-position: -144px -11px;
}

.flag.flag-bn {
	background-position: -160px -11px;
}

.flag.flag-bo {
	background-position: -176px -11px;
}

.flag.flag-br {
	background-position: -192px -11px;
}

.flag.flag-bs {
	background-position: -208px -11px;
}

.flag.flag-bt {
	background-position: -224px -11px;
}

.flag.flag-bv {
	background-position: -240px -11px;
}

.flag.flag-bw {
	background-position: 0 -22px;
}

.flag.flag-by {
	background-position: -16px -22px;
}

.flag.flag-bz {
	background-position: -32px -22px;
}

.flag.flag-ca {
	background-position: -48px -22px;
}

.flag.flag-catalonia {
	background-position: -64px -22px;
}

.flag.flag-cd {
	background-position: -80px -22px;
}

.flag.flag-cf {
	background-position: -96px -22px;
}

.flag.flag-cg {
	background-position: -112px -22px;
}

.flag.flag-ch {
	background-position: -128px -22px;
}

.flag.flag-ci {
	background-position: -144px -22px;
}

.flag.flag-ck {
	background-position: -160px -22px;
}

.flag.flag-cl {
	background-position: -176px -22px;
}

.flag.flag-cm {
	background-position: -192px -22px;
}

.flag.flag-cn {
	background-position: -208px -22px;
}

.flag.flag-co {
	background-position: -224px -22px;
}

.flag.flag-cr {
	background-position: -240px -22px;
}

.flag.flag-cu {
	background-position: 0 -33px;
}

.flag.flag-cv {
	background-position: -16px -33px;
}

.flag.flag-cw {
	background-position: -32px -33px;
}

.flag.flag-cy {
	background-position: -48px -33px;
}

.flag.flag-cz {
	background-position: -64px -33px;
}

.flag.flag-de {
	background-position: -80px -33px;
}

.flag.flag-dj {
	background-position: -96px -33px;
}

.flag.flag-dk {
	background-position: -112px -33px;
}

.flag.flag-dm {
	background-position: -128px -33px;
}

.flag.flag-do {
	background-position: -144px -33px;
}

.flag.flag-dz {
	background-position: -160px -33px;
}

.flag.flag-ec {
	background-position: -176px -33px;
}

.flag.flag-ee {
	background-position: -192px -33px;
}

.flag.flag-eg {
	background-position: -208px -33px;
}

.flag.flag-eh {
	background-position: -224px -33px;
}

.flag.flag-england {
	background-position: -240px -33px;
}

.flag.flag-er {
	background-position: 0 -44px;
}

.flag.flag-es {
	background-position: -16px -44px;
}

.flag.flag-et {
	background-position: -32px -44px;
}

.flag.flag-eu {
	background-position: -48px -44px;
}

.flag.flag-fi {
	background-position: -64px -44px;
}

.flag.flag-fj {
	background-position: -80px -44px;
}

.flag.flag-fk {
	background-position: -96px -44px;
}

.flag.flag-fm {
	background-position: -112px -44px;
}

.flag.flag-fo {
	background-position: -128px -44px;
}

.flag.flag-fr {
	background-position: -144px -44px;
}

.flag.flag-ga {
	background-position: -160px -44px;
}

.flag.flag-gb {
	background-position: -176px -44px;
}

.flag.flag-gd {
	background-position: -192px -44px;
}

.flag.flag-ge {
	background-position: -208px -44px;
}

.flag.flag-gf {
	background-position: -224px -44px;
}

.flag.flag-gg {
	background-position: -240px -44px;
}

.flag.flag-gh {
	background-position: 0 -55px;
}

.flag.flag-gi {
	background-position: -16px -55px;
}

.flag.flag-gl {
	background-position: -32px -55px;
}

.flag.flag-gm {
	background-position: -48px -55px;
}

.flag.flag-gn {
	background-position: -64px -55px;
}

.flag.flag-gp {
	background-position: -80px -55px;
}

.flag.flag-gq {
	background-position: -96px -55px;
}

.flag.flag-gr {
	background-position: -112px -55px;
}

.flag.flag-gs {
	background-position: -128px -55px;
}

.flag.flag-gt {
	background-position: -144px -55px;
}

.flag.flag-gu {
	background-position: -160px -55px;
}

.flag.flag-gw {
	background-position: -176px -55px;
}

.flag.flag-gy {
	background-position: -192px -55px;
}

.flag.flag-hk {
	background-position: -208px -55px;
}

.flag.flag-hm {
	background-position: -224px -55px;
}

.flag.flag-hn {
	background-position: -240px -55px;
}

.flag.flag-hr {
	background-position: 0 -66px;
}

.flag.flag-ht {
	background-position: -16px -66px;
}

.flag.flag-hu {
	background-position: -32px -66px;
}

.flag.flag-ic {
	background-position: -48px -66px;
}

.flag.flag-id {
	background-position: -64px -66px;
}

.flag.flag-ie {
	background-position: -80px -66px;
}

.flag.flag-il {
	background-position: -96px -66px;
}

.flag.flag-im {
	background-position: -112px -66px;
}

.flag.flag-in {
	background-position: -128px -66px;
}

.flag.flag-io {
	background-position: -144px -66px;
}

.flag.flag-iq {
	background-position: -160px -66px;
}

.flag.flag-ir {
	background-position: -176px -66px;
}

.flag.flag-is {
	background-position: -192px -66px;
}

.flag.flag-it {
	background-position: -208px -66px;
}

.flag.flag-je {
	background-position: -224px -66px;
}

.flag.flag-jm {
	background-position: -240px -66px;
}

.flag.flag-jo {
	background-position: 0 -77px;
}

.flag.flag-jp {
	background-position: -16px -77px;
}

.flag.flag-ke {
	background-position: -32px -77px;
}

.flag.flag-kg {
	background-position: -48px -77px;
}

.flag.flag-kh {
	background-position: -64px -77px;
}

.flag.flag-ki {
	background-position: -80px -77px;
}

.flag.flag-km {
	background-position: -96px -77px;
}

.flag.flag-kn {
	background-position: -112px -77px;
}

.flag.flag-kp {
	background-position: -128px -77px;
}

.flag.flag-kr {
	background-position: -144px -77px;
}

.flag.flag-kurdistan {
	background-position: -160px -77px;
}

.flag.flag-kw {
	background-position: -176px -77px;
}

.flag.flag-ky {
	background-position: -192px -77px;
}

.flag.flag-kz {
	background-position: -208px -77px;
}

.flag.flag-la {
	background-position: -224px -77px;
}

.flag.flag-lb {
	background-position: -240px -77px;
}

.flag.flag-lc {
	background-position: 0 -88px;
}

.flag.flag-li {
	background-position: -16px -88px;
}

.flag.flag-lk {
	background-position: -32px -88px;
}

.flag.flag-lr {
	background-position: -48px -88px;
}

.flag.flag-ls {
	background-position: -64px -88px;
}

.flag.flag-lt {
	background-position: -80px -88px;
}

.flag.flag-lu {
	background-position: -96px -88px;
}

.flag.flag-lv {
	background-position: -112px -88px;
}

.flag.flag-ly {
	background-position: -128px -88px;
}

.flag.flag-ma {
	background-position: -144px -88px;
}

.flag.flag-mc {
	background-position: -160px -88px;
}

.flag.flag-md {
	background-position: -176px -88px;
}

.flag.flag-me {
	background-position: -192px -88px;
}

.flag.flag-mg {
	background-position: -208px -88px;
}

.flag.flag-mh {
	background-position: -224px -88px;
}

.flag.flag-mk {
	background-position: -240px -88px;
}

.flag.flag-ml {
	background-position: 0 -99px;
}

.flag.flag-mm {
	background-position: -16px -99px;
}

.flag.flag-mn {
	background-position: -32px -99px;
}

.flag.flag-mo {
	background-position: -48px -99px;
}

.flag.flag-mp {
	background-position: -64px -99px;
}

.flag.flag-mq {
	background-position: -80px -99px;
}

.flag.flag-mr {
	background-position: -96px -99px;
}

.flag.flag-ms {
	background-position: -112px -99px;
}

.flag.flag-mt {
	background-position: -128px -99px;
}

.flag.flag-mu {
	background-position: -144px -99px;
}

.flag.flag-mv {
	background-position: -160px -99px;
}

.flag.flag-mw {
	background-position: -176px -99px;
}

.flag.flag-mx {
	background-position: -192px -99px;
}

.flag.flag-my {
	background-position: -208px -99px;
}

.flag.flag-mz {
	background-position: -224px -99px;
}

.flag.flag-na {
	background-position: -240px -99px;
}

.flag.flag-nc {
	background-position: 0 -110px;
}

.flag.flag-ne {
	background-position: -16px -110px;
}

.flag.flag-nf {
	background-position: -32px -110px;
}

.flag.flag-ng {
	background-position: -48px -110px;
}

.flag.flag-ni {
	background-position: -64px -110px;
}

.flag.flag-nl {
	background-position: -80px -110px;
}

.flag.flag-no {
	background-position: -96px -110px;
}

.flag.flag-np {
	background-position: -112px -110px;
}

.flag.flag-nr {
	background-position: -128px -110px;
}

.flag.flag-nu {
	background-position: -144px -110px;
}

.flag.flag-nz {
	background-position: -160px -110px;
}

.flag.flag-om {
	background-position: -176px -110px;
}

.flag.flag-pa {
	background-position: -192px -110px;
}

.flag.flag-pe {
	background-position: -208px -110px;
}

.flag.flag-pf {
	background-position: -224px -110px;
}

.flag.flag-pg {
	background-position: -240px -110px;
}

.flag.flag-ph {
	background-position: 0 -121px;
}

.flag.flag-pk {
	background-position: -16px -121px;
}

.flag.flag-pl {
	background-position: -32px -121px;
}

.flag.flag-pm {
	background-position: -48px -121px;
}

.flag.flag-pn {
	background-position: -64px -121px;
}

.flag.flag-pr {
	background-position: -80px -121px;
}

.flag.flag-ps {
	background-position: -96px -121px;
}

.flag.flag-pt {
	background-position: -112px -121px;
}

.flag.flag-pw {
	background-position: -128px -121px;
}

.flag.flag-py {
	background-position: -144px -121px;
}

.flag.flag-qa {
	background-position: -160px -121px;
}

.flag.flag-re {
	background-position: -176px -121px;
}

.flag.flag-ro {
	background-position: -192px -121px;
}

.flag.flag-rs {
	background-position: -208px -121px;
}

.flag.flag-ru {
	background-position: -224px -121px;
}

.flag.flag-rw {
	background-position: -240px -121px;
}

.flag.flag-sa {
	background-position: 0 -132px;
}

.flag.flag-sb {
	background-position: -16px -132px;
}

.flag.flag-sc {
	background-position: -32px -132px;
}

.flag.flag-scotland {
	background-position: -48px -132px;
}

.flag.flag-sd {
	background-position: -64px -132px;
}

.flag.flag-se {
	background-position: -80px -132px;
}

.flag.flag-sg {
	background-position: -96px -132px;
}

.flag.flag-sh {
	background-position: -112px -132px;
}

.flag.flag-si {
	background-position: -128px -132px;
}

.flag.flag-sk {
	background-position: -144px -132px;
}

.flag.flag-sl {
	background-position: -160px -132px;
}

.flag.flag-sm {
	background-position: -176px -132px;
}

.flag.flag-sn {
	background-position: -192px -132px;
}

.flag.flag-so {
	background-position: -208px -132px;
}

.flag.flag-somaliland {
	background-position: -224px -132px;
}

.flag.flag-sr {
	background-position: -240px -132px;
}

.flag.flag-ss {
	background-position: 0 -143px;
}

.flag.flag-st {
	background-position: -16px -143px;
}

.flag.flag-sv {
	background-position: -32px -143px;
}

.flag.flag-sx {
	background-position: -48px -143px;
}

.flag.flag-sy {
	background-position: -64px -143px;
}

.flag.flag-sz {
	background-position: -80px -143px;
}

.flag.flag-tc {
	background-position: -96px -143px;
}

.flag.flag-td {
	background-position: -112px -143px;
}

.flag.flag-tf {
	background-position: -128px -143px;
}

.flag.flag-tg {
	background-position: -144px -143px;
}

.flag.flag-th {
	background-position: -160px -143px;
}

.flag.flag-tibet {
	background-position: -176px -143px;
}

.flag.flag-tj {
	background-position: -192px -143px;
}

.flag.flag-tk {
	background-position: -208px -143px;
}

.flag.flag-tl {
	background-position: -224px -143px;
}

.flag.flag-tm {
	background-position: -240px -143px;
}

.flag.flag-tn {
	background-position: 0 -154px;
}

.flag.flag-to {
	background-position: -16px -154px;
}

.flag.flag-tr {
	background-position: -32px -154px;
}

.flag.flag-tt {
	background-position: -48px -154px;
}

.flag.flag-tv {
	background-position: -64px -154px;
}

.flag.flag-tw {
	background-position: -80px -154px;
}

.flag.flag-tz {
	background-position: -96px -154px;
}

.flag.flag-ua {
	background-position: -112px -154px;
}

.flag.flag-ug {
	background-position: -128px -154px;
}

.flag.flag-um {
	background-position: -144px -154px;
}

.flag.flag-us {
	background-position: -160px -154px;
}

.flag.flag-uy {
	background-position: -176px -154px;
}

.flag.flag-uz {
	background-position: -192px -154px;
}

.flag.flag-va {
	background-position: -208px -154px;
}

.flag.flag-vc {
	background-position: -224px -154px;
}

.flag.flag-ve {
	background-position: -240px -154px;
}

.flag.flag-vg {
	background-position: 0 -165px;
}

.flag.flag-vi {
	background-position: -16px -165px;
}

.flag.flag-vn {
	background-position: -32px -165px;
}

.flag.flag-vu {
	background-position: -48px -165px;
}

.flag.flag-wales {
	background-position: -64px -165px;
}

.flag.flag-wf {
	background-position: -80px -165px;
}

.flag.flag-ws {
	background-position: -96px -165px;
}

.flag.flag-xk {
	background-position: -112px -165px;
}

.flag.flag-ye {
	background-position: -128px -165px;
}

.flag.flag-yt {
	background-position: -144px -165px;
}

.flag.flag-za {
	background-position: -160px -165px;
}

.flag.flag-zanzibar {
	background-position: -176px -165px;
}

.flag.flag-zm {
	background-position: -192px -165px;
}

.flag.flag-zw {
	background-position: -208px -165px;
}

.loading-overlay-showing {
	overflow: hidden;
}

.loading-overlay-showing > .loading-overlay {
	opacity: 1;
	visibility: visible;
}

.loading-overlay {
	transition: visibility 0s ease-in-out 0.5s, opacity 0.5s ease-in-out;
	bottom: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	right: 0;
	top: 0;
	visibility: hidden;
	background: #FFF;
}

body > .loading-overlay {
	position: fixed;
	z-index: 999999;
}

/* Bounce Loading */
.bounce-loader {
	transition: all 0.2;
	margin: -9px 0 0 -35px;
	text-align: center;
	width: 70px;
	left: 50%;
	position: absolute;
	top: 50%;
	z-index: 10000;
}

.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3 {
	animation: 1.4s ease-in-out 0s normal both infinite running bouncedelay;
	background-color: #CCC;
	border-radius: 100%;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
	display: inline-block;
	height: 18px;
	width: 18px;
}

.bounce-loader .bounce1 {
	animation-delay: -0.32s;
}

.bounce-loader .bounce2 {
	animation-delay: -0.16s;
}

@keyframes bouncedelay {
	0%, 80%, 100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}
/* Portfolio */
.portfolio-title {
	margin-bottom: -10px;
}

.portfolio-title a {
	display: inline-block;
}

.portfolio-title .fa, .portfolio-title .icons {
	font-size: 1.7em;
}

.portfolio-title .portfolio-nav {
	margin-top: 11px;
}

.portfolio-title .portfolio-nav-all {
	margin-top: 9px;
}

.portfolio-title .portfolio-nav {
	text-align: right;
}

.portfolio-title .portfolio-nav a {
	width: 40%;
}

.portfolio-title .portfolio-nav-all .fa,
.portfolio-title .portfolio-nav-all .icons {
	font-size: 2em;
}

.portfolio-title .fa-angle-right {
	text-align: right;
}

.portfolio-title .portfolio-nav-prev {
	text-align: left;
}

/* Portfolio Info */
.portfolio-info {
	margin: 2px 0 10px;
	float: right;
}

.portfolio-info ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.portfolio-info ul li {
	display: inline-block;
	border-right: 1px solid #E6E6E6;
	color: #B1B1B1;
	padding-right: 8px;
	margin-right: 8px;
	font-size: 0.9em;
}

.portfolio-info ul li:last-child {
	border-right: 0;
	padding-right: 0;
	margin-right: 0;
}

.portfolio-info ul li a {
	text-decoration: none;
	color: #B1B1B1;
}

.portfolio-info ul li a:hover .fa-heart {
	color: #E53F51;
}

.portfolio-info ul li .fa, .portfolio-info ul li .icons {
	margin-right: 4px;
}

/* Portfolio List */
ul.portfolio-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.portfolio-list .portfolio-item {
	position: relative;
	margin-bottom: 35px;
}

ul.portfolio-list.portfolio-list-no-margins {
	margin-left: 15px;
	margin-right: 15px;
}

/* Portfolio Details */
ul.portfolio-details {
	list-style: none;
	padding: 0;
	margin: 45px 0 0 5px;
	font-size: 0.9em;
}

ul.portfolio-details > li {
	padding: 0;
	margin: 0 0 20px;
}

ul.portfolio-details p {
	padding: 0;
	margin: 0;
}

ul.portfolio-details blockquote {
	padding: 0 0 0 10px;
	margin: 15px 0 0;
	color: #999;
}

ul.portfolio-details .list-icons {
	margin-left: 0;
}

ul.portfolio-details .list-icons > li {
	padding-left: 18px;
	margin-right: 10px;
}

ul.portfolio-details .list-icons > li .fa, ul.portfolio-details .list-icons > li .icons {
	top: 7px;
}

/* Portfolio Modal */
.portfolio-modal .mfp-arrow,
.portfolio-modal .mfp-close,
.portfolio-ajax-modal .mfp-arrow,
.portfolio-ajax-modal .mfp-close {
	display: none;
}

.portfolio-load-more-loader {
	display: none;
	position: relative;
	height: 49px;
}

.btn-portfolio-infinite-scroll {
	opacity: 0;
}

/* Responsive */
@media (max-width: 991px) {
	.portfolio-nav, .portfolio-nav-all {
		display: none;
	}
}
/* Team List */
ul.team-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.team-list .team-item {
	margin-bottom: 35px;
}

ul.team-list .team-item .thumb-info-social-icons {
	margin: 0 10px;
}

/* Responsive */
@media (max-width: 991px) {
	ul.team-list .team-item {
		margin: 0 auto 35px;
		max-width: 270px;
	}
}
/* History */
ul.history {
	margin: 0 0 25px;
	padding: 0;
	list-style: none;
}

ul.history li {
	margin: 25px 0;
	padding: 0;
	clear: both;
}

ul.history li h4 {
	margin: 0 0 -27px 0;
	padding: 0;
}

ul.history li p {
	margin-left: 53px;
}

ul.history li .thumb {
	background: transparent url(../img/history-thumb.png) no-repeat 0 0;
	width: 227px;
	height: 160px;
	margin: 0 0 30px;
	padding: 0;
	float: left;
}

ul.history li .thumb img {
	border-radius: 150px;
	margin: 7px 8px;
	width: 145px;
	height: 145px;
}

ul.history li .featured-box {
	text-align: left;
	margin-left: 225px;
	margin-bottom: 50px;
	min-height: 115px;
}

ul.history li .featured-box .box-content {
	border-top-color: #CCC;
}

/* Responsive */
@media (max-width: 479px) {
	ul.history li .thumb {
		display: none;
	}

	ul.history li .featured-box {
		margin-left: 0;
	}
}
/* Search Results */
.search-results {
	min-height: 300px;
}

/* Maps */
.google-map {
	background: #E5E3DF;
	height: 400px;
	margin: -35px 0 30px 0;
	width: 100%;
}

.google-map.small {
	height: 250px;
	border-radius: 6px;
	margin: 0;
	clear: both;
}

.google-map img {
	max-width: 9999px;
}

.google-map-borders {
	border: 1px solid #DDD;
	padding: 4px;
	border-radius: 4px;
}

/* Google Map */
.google-map-footer {
	margin: 35px 0 -50px !important;
}
