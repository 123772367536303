.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropzone {
  width: 100%;
  border: 1px solid lightgrey;
  padding: 20px;
  text-align: center;
  color: lightgrey;
}

.left-margin {
  margin-left: 10px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 3;
}

.reorder {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: break-spaces;
  float: left;
  width: 100%;
  height: auto;
  border: 1px solid grey;
  padding: 8px;
  display: inline-flex;
}

.list-item {
  position: relative;
  float: none;
  width: 370px !important;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  height: auto;
  margin: 5px;
  border: 2px solid lightblue;
  transform-origin: 50% 50%;
}

.dragged {
  background-color: #eee;
  transform: scale(0.98, 0.98);
  opacity: 0.7;
}

.selected {
  border: 2px solid red;
}

.placeholder {
  background-color: #ccc;
  border: 2px solid #ccc;
  width: 80px;
}

.placeholder .inner {
  visibility: hidden;
}

#custom {
  padding-bottom: 20px;
}

main {
  margin-bottom: 0 !important;
}

.margin-right {
  margin-right: 10px;
}

.margin-bottom {
  margin-bottom: 10px;
}

.position-top {
  position: absolute;
}

.my-list-2 {
  float: left;
  width: 100%;
  border: 1px solid grey;
  padding: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 62px;
  white-space: nowrap;
}

.list-item {
  width: 220px !important;
  height: 220px;
  padding: 12px;
  border: 2px solid lightblue;
  transform-origin: 50% 50%;
  float: none;
  margin: 5px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.panel-heading :hover {
  cursor: pointer;
}

.react-grid-layout {
  position: relative;
}

.documentTypeFilter {
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  margin-bottom: 10px;
}
