.custom-primary-font {
	font-family: "Poppins", sans-serif !important;
}

h1, h2, h3, h4, h5, h6, a, p, span, li, input {
	font-family: "Poppins", sans-serif;
}

h1 {
	font-size: 2.9em;
}

.small-h1 {
	font-size: 1.8em;
	line-height: 27px;
	margin: 0;
}

h2 {
	font-size: 1.8em;
	line-height: 27px;
	margin: 0;
}

h3 {
	font-size: 1.2em;
	letter-spacing: -1px;
}

h4 {
	font-size: 1.1em;
	line-height: 18px;
}

.custom-text-color-1 {
	color: #818898 !important;
}

.custom-text-color-2 {
	color: #c6d4ee !important;
}

.custom-text-color-3 {
	color: #94a9c3 !important;
}

.custom-text-color-4 {
	color: #d9e0ef !important;
}

.custom-btn-background-color-1 {
	background: rgba(255, 255, 255, 0.2) !important;
}
.custom-btn-background-color-1:hover {
	background: rgba(255, 255, 255, 0.3) !important;
}

.custom-primary-hover {
	-webkit-transition: ease color 300ms;
	-moz-transition: ease color 300ms;
	transition: ease color 300ms;
}
.custom-primary-hover:hover {
	color: #6a80a9 !important;
}

.custom-img-rounded {
	border-radius: 100% !important;
}

.custom-min-height-1 {
	min-height: 190px;
}

.custom-background-1 {
	position: relative;
}
.custom-background-1:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 68.4%;
	width: 100vw;
	height: 100%;
	background: #e5edf1;
	z-index: 0;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}
@media (max-width: 991px) {
	.custom-background-1:after {
		left: 50%;
	}
}
.custom-background-1 > * {
	position: relative;
	z-index: 1;
}

.custom-btn-style-1 {
	padding: 13px 25px !important;
	font-size: 12px;
	border-radius: 2px !important;
}

.custom-btn-style-2 {
	padding: 13px 30px;
	font-size: 12px;
	border-width: 2px;
}

.custom-btn-style-3 {
	border-width: 1px;
	padding: 10px 20px;
}

.custom-btn-style-4 {
	background: none;
	color: #FFF;
	font-weight: 900;
	border: 2px solid #FFF;
	padding: 10px 20px;
}
.custom-btn-style-4:hover {
	background: #FFF;
	color: #6a80a9;
}

.custom-text-size-1 {
	font-size: 2.2em !important;
}

.custom-list {
	padding: 0;
}
.custom-list li {
	list-style: none;
}

.custom-list-style-1 {
	padding: 0;
}
.custom-list-style-1 li {
	list-style: none;
	line-height: 2.5;
}
.custom-list-style-1 li i {
	line-height: 2.4;
}

.custom-list-style-2 {
	padding: 0;
}
.custom-list-style-2 li {
	display: inline-block;
	list-style: none;
	margin: 0 6px 10px 0px;
}

.custom-list-style-3 {
	padding: 0;
}
.custom-list-style-3 li {
	list-style: none;
}
.custom-list-style-3 li .title {
	display: inline-block;
	width: 80px;
	font-weight: 600;
}

.custom-right-border {
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 991px) {
	.custom-right-border {
		border-right: 0;
	}
}

.custom-bottom-bar:before {
	content: '';
	display: block;
	position: absolute;
	bottom: 17px;
	width: 20px;
	left: 50%;
	border-bottom: 3px solid #6a80a9;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}
@media (max-width: 991px) {
	.custom-bottom-bar:before {
		bottom: -15px;
	}
}

.custom-item-divider-1 {
	position: relative;
}
.custom-item-divider-1:before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	right: -16px;
	height: 37px;
	border-right: 1px solid #e4e7eb;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.custom-border-none {
	border: none !important;
}

.custom-section-spacement-1 {
	padding: 60px 0 !important;
}

.custom-section-spacement-2 {
	padding: 0 0 60px !important;
}

.custom-parallax-bg-pos-left {
	background-position-x: 0% !important;
}

.custom-parallax-medium-padding {
	padding: 128px 0 !important;
}

.custom-parallax-big-padding {
	padding: 25vh 0 !important;
}

.custom-text-align-right {
	text-align: right !important;
}

.custom-center-img-responsive {
	margin: 0 auto;
}

@media (max-width: 767px) {
	.custom-center-xs {
		text-align: center !important;
	}
}
.custom-opacity-effect-1 {
	-webkit-transition: ease opacity 300ms;
	-moz-transition: ease opacity 300ms;
	transition: ease opacity 300ms;
}
.custom-opacity-effect-1:hover {
	opacity: 0.5;
}

.custom-btn-padding-1 {
	padding: 13px 35px !important;
	font-size: 13px;
}

@media (min-width: 768px) {
	.custom-padding-right-1 {
		padding-right: 160px;
	}
}
@media (max-width: 991px) {
	.custom-sm-margin-bottom-1 {
		margin-bottom: 25px !important;
	}

	.custom-sm-margin-bottom-2 {
		margin-bottom: 15px !important;
	}
}
@media (max-width: 767px) {
	.custom-xs-margin-bottom-1 {
		margin-bottom: 25px !important;
	}
}
@media (max-width: 991px) {
	.custom-sm-flex-order {
		display: flex;
		flex-flow: row wrap;
	}
	.custom-sm-flex-order .col-md-6 {
		width: 100%;
	}
	.custom-sm-flex-order .col-md-6:nth-child(1) {
		order: 2;
	}
	.custom-sm-flex-order .col-md-6:nth-child(2) {
		order: 1;
	}

	.custom-sm-flex-order-2 {
		display: flex;
		flex-flow: row wrap;
	}
	.custom-sm-flex-order-2 .col-md-3 {
		width: 100%;
		order: 3;
	}
	.custom-sm-flex-order-2 .col-md-9 {
		width: 100%;
	}
}
.social-icons.custom-social-icons li {
	list-style: none;
	border-radius: 0;
	border: 1px solid #c9c9cc;
	box-shadow: none;
	margin-right: 5px;
}
.social-icons.custom-social-icons li a {
	border-radius: 0;
	line-height: 33px;
	color: #93949a !important;
}
.social-icons.custom-social-icons li:hover a {
	color: #FFF !important;
}
.social-icons.custom-style-1 li {
	background: none;
	box-shadow: none;
	margin-bottom: 0;
	line-height: 1;
}
.social-icons.custom-style-1 li:hover a {
	background: none;
}
.social-icons.custom-style-1 li:hover.social-icons-facebook a {
	color: #3b5a9a !important;
}
.social-icons.custom-style-1 li:hover.social-icons-twitter a {
	color: #1aa9e1 !important;
}
.social-icons.custom-style-1 li:hover.social-icons-linkedin a {
	color: #0073b2 !important;
}
.social-icons.custom-style-1 li a {
	line-height: 1;
	width: 20px;
	height: initial;
	color: #6a80a9 !important;
}

/*
* Owl Carousel
*/
.owl-carousel.custom-nav .owl-nav {
	position: relative;
	top: initial;
	bottom: 105px;
	left: 50%;
	width: 230px;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}
.owl-carousel.custom-nav .owl-nav .owl-next, .owl-carousel.custom-nav .owl-nav .owl-prev {
	background: transparent !important;
}
.owl-carousel.custom-nav .owl-nav .owl-next:before, .owl-carousel.custom-nav .owl-nav .owl-prev:before {
	content: '';
	display: block;
	position: absolute;
	left: -3px;
	top: 6px;
	width: 20px;
	height: 20px;
	border-top: 1px solid #2e353e;
	border-left: 1px solid #2e353e;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.owl-carousel.custom-nav .owl-nav .owl-next {
	right: -15px;
}
.owl-carousel.custom-nav .owl-nav .owl-next:before {
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

/*
* Header
*/
#header .header-body {
	border: none;
}
#header .header-nav-main.custom-header-nav-main-dark-style nav > ul > li:hover > a, #header .header-nav-main.custom-header-nav-main-dark-style nav > ul > li.active > a {
	color: #6a80a9;
}
#header .header-nav-main.custom-header-nav-main-dark-style nav > ul > li:hover > a:after, #header .header-nav-main.custom-header-nav-main-dark-style nav > ul > li.active > a:after {
	border-color: #6a80a9 transparent transparent transparent !important;
}
#header .header-nav-main.custom-header-nav-main-dark-style nav > ul > li > a {
	background: transparent !important;
	color: #2e353e;
	font-weight: 600;
}
#header .header-nav-main.custom-header-nav-main-dark-style nav > ul > li > a:after {
	border-color: #2e353e transparent transparent transparent !important;
}
#header .header-nav-main.custom-header-nav-main-dark-style nav > ul > li ul li a {
	font-size: 12px !important;
}

@media (min-width: 992px) {
	#header .header-nav-main nav > ul > li > a {
		font-size: 13px;
		padding: 10px 20px;
	}
}
/*
* Revolution
*/
.tparrows {
	background: transparent !important;
}
.tparrows:before {
	opacity: 0.7;
	color: #000 !important;
	font-size: 30px;
}
.tparrows:hover:before {
	opacity: 1;
}

/*
* Toll Free
*/
.custom-toll-free {
	position: relative;
	padding-left: 35px;
	margin-left: 40px;
}
.custom-toll-free > span {
	display: block;
}
.custom-toll-free > span.toll-free-number {
	font-size: 1.5em;
	letter-spacing: -1px;
}
.custom-toll-free > i {
	position: absolute;
	top: 2px;
	left: 0;
	font-size: 4em;
	line-height: 0.8;
}

/*
* Home Intro
*/
.custom-home-intro {
	padding: 20px 0 20px 0;
}
.custom-home-intro p {
	max-width: none;
}
.custom-home-intro p span {
	position: relative;
	display: inline-block;
	padding-top: 0;
}
.custom-home-intro p span:before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	right: -40px;
	width: 30px;
	border-bottom: 1px solid #FFF;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}
.custom-home-intro p span:after {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	right: -44px;
	width: 0;
	height: 0;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 5px solid #FFF;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

/*
* Company
*/
.custom-timeline {
	margin-top: 50px;
}
.custom-timeline .custom-timeline-box {
	position: relative;
	padding-left: 105px;
}
.custom-timeline .custom-timeline-box:before {
	background: none repeat scroll 0 0 #6a80a9;
	border-radius: 50%;
	box-shadow: 0 0 0 3px #FFF, 0 0 0 6px #6a80a9;
	content: "";
	display: block;
	height: 6px;
	margin-left: -5px;
	position: absolute;
	left: 68px;
	top: 6px;
	width: 6px;
	z-index: 2;
}
.custom-timeline .custom-timeline-box:after {
	content: '';
	display: block;
	position: absolute;
	left: 65px;
	top: 3px;
	height: 100%;
	border-left: 1px solid #d5dee1;
	z-index: 1;
}
.custom-timeline .custom-timeline-box .date {
	position: absolute;
	top: 0;
	left: 0;
}

.custom-contact-item {
	padding: 0 0 30px;
}
.custom-contact-item i {
	font-size: 2em;
	margin-bottom: 20px;
	display: block;
}
.custom-contact-item span {
	display: block;
}
.custom-contact-item span.title {
	font-size: 0.8em;
}
.custom-contact-item span.content {
	font-size: 1.5em;
}

.custom-hover-opacity {
	display: block;
	-webkit-transition: ease opacity 300ms;
	-moz-transition: ease opacity 300ms;
	transition: ease opacity 300ms;
}
.custom-hover-opacity:hover {
	opacity: 0.7;
}

/*
* Strategies / Services
*/
.custom-post-style-1 {
	margin-bottom: 60px;
}
.custom-post-style-1:nth-child(4) {
	background: #000;
}
.custom-post-style-1.custom-rm-mb {
	margin-bottom: 0 !important;
}
@media (max-width: 991px) {
	.custom-post-style-1.custom-rm-mb {
		margin-bottom: 60px !important;
	}
}
.custom-post-style-1.custom-rm-mb-last {
	margin-bottom: 0 !important;
}

/*
* Counter
*/
.custom-counters .counter span {
	line-height: 1;
	font-size: 3.6em;
}
.custom-counters .counter span:last-child {
	display: block;
	font-size: 1.1em;
}

/*
* Team
*/
.custom-thumb-info-1 {
	overflow: initial;
	margin-bottom: 30px !important;
	-webkit-transition: ease box-shadow 300ms;
	-moz-transition: ease box-shadow 300ms;
	transition: ease box-shadow 300ms;
}
.custom-thumb-info-1:hover {
	box-shadow: 0px 0px 70px 0px rgba(139, 139, 139, 0.5);
}
.custom-thumb-info-1:hover .thumb-info-wrapper:after {
	content: none;
}
.custom-thumb-info-1:hover .thumb-info-caption {
	border-bottom: 0;
}
.custom-thumb-info-1 .thumb-info-wrapper {
	-webkit-transform: initial;
	-moz-transform: initial;
	-ms-transform: initial;
	-o-transform: initial;
	transform: initial;
}
.custom-thumb-info-1 .thumb-info-caption {
	position: relative;
	display: block;
	padding: 0;
	border-bottom: 1px solid #e3e3e3 !important;
	-webkit-transition: ease border-bottom 300ms;
	-moz-transition: ease border-bottom 300ms;
	transition: ease border-bottom 300ms;
}
.custom-thumb-info-1 .thumb-info-caption .thumb-info-caption-text {
	padding: 30px 20px;
	margin: 0;
}
.custom-thumb-info-1 .thumb-info-caption .thumb-info-caption-text p {
	padding: 0;
	font-size: 1em;
}
.custom-thumb-info-1 .thumb-info-caption .thumb-info-caption-text .custom-thumb-info-caption-plus {
	position: absolute;
	top: 30px;
	right: 20px;
	border-radius: 100%;
	font-size: 2em;
	line-height: 0.76;
	width: 30px;
	height: 30px;
	font-weight: 100;
	text-align: center;
	padding-top: 7px;
}
.custom-thumb-info-1.custom-rm-mb {
	margin-bottom: 0 !important;
}
@media (max-width: 991px) {
	.custom-thumb-info-1.custom-rm-mb {
		margin-bottom: 30px !important;
	}
}
.custom-thumb-info-1.custom-rm-mb-last {
	margin-bottom: 0 !important;
}

/*
* Testimonial
*/
.custom-testimonial-style.testimonial-with-quotes blockquote:before, .custom-testimonial-style.testimonial-with-quotes blockquote:after {
	font-size: 50px;
	color: #2e353e;
}
.custom-testimonial-style blockquote p {
	font-style: normal;
	font-size: 1em;
	line-height: 2;
}

/*
* Blog
*/
.custom-post-blog {
	position: relative;
}
.custom-post-blog > h4, .custom-post-blog > p {
	padding: 0 30px 0 90px;
}
.custom-post-blog .date {
	position: absolute;
	top: 5px;
	left: 0;
}
.custom-post-blog .date > span {
	display: block;
}
.custom-post-blog .date > span.day {
	font-size: 3.5em;
	line-height: 0.7;
}

.custom-post-style-2 {
	position: relative;
	margin-bottom: 60px;
}
@media (max-width: 991px) {
	.custom-post-style-2 {
		margin-bottom: 0;
	}
}
.custom-post-style-2 .post-infos {
	position: absolute;
	top: 0;
	left: -110px;
}
.custom-post-style-2 .post-infos .post-info {
	position: relative;
	display: block;
	padding: 20px 0px;
}
.custom-post-style-2 .post-infos .post-info:after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 80%;
	border-bottom: 1px solid #e4e7eb;
}
.custom-post-style-2 .post-infos .post-info:last-child:after {
	content: none;
}
.custom-post-style-2 .post-infos .post-info span {
	display: block;
	line-height: 1;
}
.custom-post-style-2 .post-links {
	border-top: 1px solid #e4e7eb;
	border-bottom: 1px solid #e4e7eb;
	padding: 8px 0px;
	margin-top: 40px;
}
.custom-post-style-2 .post-links:after {
	content: '';
	display: block;
	clear: both;
}

@media (max-width: 767px) {
	.custom-post-style-2 .post-infos {
		position: initial;
		text-align: center;
	}
	.custom-post-style-2 .post-infos .post-info {
		display: inline-block;
		margin-right: 20px;
	}
	.custom-post-style-2 .post-infos .post-info img {
		display: initial;
	}
}
.custom-testimonial-blog-1 {
	text-align: left !important;
	margin-bottom: 15px !important;
}
.custom-testimonial-blog-1 blockquote {
	padding: 10px 30px !important;
}
.custom-testimonial-blog-1 blockquote:before, .custom-testimonial-blog-1 blockquote:after {
	color: #2e353e !important;
}
.custom-testimonial-blog-1 blockquote p {
	font-size: 1.1em !important;
	line-height: 1.8;
	font-family: "Poppins", sans-serif !important;
	font-weight: 500 !important;
}

ul.custom-comments li {
	border-top: 1px solid #e4e7eb;
}
ul.custom-comments li .comment {
	padding: 15px 0 30px;
}
ul.custom-comments > li:first-child {
	border-top: 0;
}
ul.custom-comments .comment-block {
	background: transparent;
	padding: 10px;
}

.custom-contact-form-style-1 .form-control {
	border: none;
	padding: 23px;
}

.custom-pagination-style-1 {
	box-shadow: 0px 4px 40px -5px rgba(0, 0, 0, 0.3);
}
.custom-pagination-style-1 > li.active > a {
	color: #FFF !important;
}
.custom-pagination-style-1 > li:hover > a {
	background: #6a80a9;
	border-color: #6a80a9 #6a80a9 #6a80a9;
	color: #FFF !important;
}
.custom-pagination-style-1 > li > a {
	font-weight: 600;
	font-size: 12px;
}

.custom-sidebar h4 {
	font-size: 18px;
}
@media (max-width: 991px) {
	.custom-sidebar {
		margin-top: 60px;
	}
}

.custom-sidebar-search .input-group {
	width: 100%;
}
.custom-sidebar-search .input-group .form-control {
	padding: 20px;
}
.custom-sidebar-search .input-group-btn .btn-default {
	background: transparent;
	border: 0 none;
	line-height: 36px;
	font-size: 19px;
	color: #2e353e;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	z-index: 3;
}

.custom-featured-posts .custom-featured-post {
	display: inline-block;
	margin-bottom: 20px;
}
.custom-featured-posts .custom-featured-post h6 {
	padding-left: 15px;
}

/*
* We Call You Back / Contact Us
*/
.custom-form-style .form-control-custom {
	position: relative;
	margin-bottom: 10px;
}
.custom-form-style .form-control-custom > i {
	position: absolute;
	top: 10px;
	left: 0;
	color: #FFF;
}
.custom-form-style .form-control-custom .form-control {
	background: transparent;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	padding: 10px 10px 10px 30px;
	box-shadow: none;
	color: #FFF;
}
.custom-form-style .form-control-custom .form-control::-webkit-input-placeholder {
	color: #c8d4ea;
}
.custom-form-style .form-control-custom .form-control::-moz-placeholder {
	color: #c8d4ea;
}
.custom-form-style .form-control-custom .form-control:-ms-input-placeholder {
	color: #c8d4ea;
}
.custom-form-style .form-control-custom select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	background: transparent;
	color: #FFF;
}
.custom-form-style .form-control-custom select.form-control option {
	color: #818898;
}
.custom-form-style .form-control-custom.custom-select-style:after {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	right: 10px;
	width: 10px;
	height: 10px;
	border-right: 1px solid #c8d4ea;
	border-bottom: 1px solid #c8d4ea;
	z-index: 0;
	-webkit-transform: translateY(-50%) rotate(45deg);
	-moz-transform: translateY(-50%) rotate(45deg);
	-ms-transform: translateY(-50%) rotate(45deg);
	-o-transform: translateY(-50%) rotate(45deg);
	transform: translateY(-50%) rotate(45deg);
}
.custom-form-style .form-control-custom.custom-select-style select {
	position: relative;
	z-index: 1;
}
.custom-form-style.contact-page .form-control-custom .form-control {
	height: initial;
}

/*
* Let's Talk
*/
.custom-feature-box {
	display: block;
}
.custom-feature-box .feature-box-info {
	padding: 0;
}
.custom-feature-box .feature-box-info > label {
	display: block;
	margin: 0;
	line-height: 1;
	font-size: 0.8em;
}
.custom-feature-box .feature-box-info > strong {
	font-size: 1.5em;
	line-height: 1.3;
}

.custom-background-image-1:before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	background: url(../../img/demos/finance/contact/lets-talk-bg.png);
	background-size: cover;
	width: 100%;
	height: 100%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
	.custom-background-image-1:before {
		left: 70%;
	}
}
/*
* Footer
*/
.custom-footer {
	border-top: 0 !important;
}
.custom-footer ul {
	padding: 0;
}
.custom-footer ul li {
	list-style: none;
}
.custom-footer .footer-copyright {
	border-top: 0 !important;
}
.custom-footer .footer-copyright .container .row > div {
	border-top: 1px solid #e4e4e4;
}

/*
* Google Maps
*/
#googlemaps {
	min-height: 550px;
}
